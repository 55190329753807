import React, { useEffect, useState } from "react";
import CustomTable from "../TableComponent/CustomTable.component";
import "./Station.css";
import axios from "axios";
import Button from "react-bootstrap/Button";
//import BootstrapSwitchButton from "bootstrap-switch-button-react";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTrash,
  faEdit,
  faCircleCheck,
  faBan,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import Header from "../header/Header";
import Footer from "../footer/Footer";

export default function Stations() {
  const [stationsData, setStationsData] = useState([]);
  const [eachStation, setEachStation] = useState({});
  const [show, setShow] = useState(false);
  const [isNew, setIsNew] = useState(true);
  const columns = [
    {
      dataField: "_id",
      text: "Id",
      hidden: true,
    },
    { dataField: "StationName", text: "Station Name", classes: 'st-name mobile-td', sort: true },
    { dataField: "StationCode", text: "Station Code", classes: 'st-code mobile-td', sort: true },
    {
      dataField: "_id",
      text: "Actions",
      formatter: ActiveFormatter,
      headerStyle: (column, colIndex) => {
        return { width: "140px" };
      },
    },
  ];

  function DeleteStation(Id) {
    axios
      .put(process.env.REACT_APP_BASE_URL +`/stations/update/${Id}`, {
        isDelete: true,
      })
      .then((response) => {
        if (response.data.success) {
          getAllStations();
        }
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }

  function ActivateStation(Id, flag) {
    axios
      .put(process.env.REACT_APP_BASE_URL +`/stations/update/${Id}`, {
        isActive: flag,
      })
      .then((response) => {
        if (response.data.success) {
          getAllStations();
        }
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }

  function makeid(length) {
    var result = "";
    var characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  function ActiveFormatter(cell, row) {
    return (
      <>
        <div className="action-btns" >
          {row.isActive && (
            <>
                <a onClick={() => onStationChanged(row)}>
                  <FontAwesomeIcon key={makeid(10)} icon={faEdit} />
                </a>
              
                <a onClick={() => ActivateStation(row._id, false)}>
                  <FontAwesomeIcon key={makeid(10)} icon={faBan} />
                </a>
             
                <a onClick={() => DeleteStation(row._id)}>
                  <FontAwesomeIcon key={makeid(10)} icon={faTrash} />
                </a>
            </>
          )}
          {!row.isActive && (
            <div className="action-btns">
              <a onClick={() => ActivateStation(row._id, true)}>
                <FontAwesomeIcon key={makeid(10)} icon={faCircleCheck} />
              </a>
            </div>
          )}
        </div>
      </>
    );
  }
  function onStationAdd() {
    setShow(true);
    setIsNew(true);
    setEachStation({});
  }
  function onStationChanged(row) {
    setShow(true);
    setIsNew(false);
    setEachStation(row);
  }
  function handleClose() {
    setShow(false);
    setIsNew(true);
    setEachStation({});
  }
  useEffect(() => {
    getAllStations();
  }, []);

  function getAllStations() {
    axios
      .get(process.env.REACT_APP_BASE_URL +"/stations/getAllStations")
      .then((response) => {
        if (response.data.success) {
          setStationsData(response.data.body);
          console.log("Stations List",stationsData)
        }
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }

  function onChange(e, controlName) {
    let eachStn = eachStation;
    eachStn[controlName] = e.target.value;
    setEachStation(eachStn);
  }
  function addStation() {
    axios
      .post(process.env.REACT_APP_BASE_URL +"/stations/create", eachStation)
      .then((response) => {
        if (response.data.success) {
          getAllStations();
          handleClose();
        } else {
          alert(response.data.error);
          handleClose();
        }
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }
  return (
    <>
      <Header/>
      <div className="page-container">
        <div className="page-header">
          <h4 className="page-title">Stations List</h4> 
          <Button
            className="float-right"
            onClick={() => {
              onStationAdd({});
            }}
          >
            <FontAwesomeIcon icon={faPlus} /> Add New Station
          </Button>
        </div>
        <div className="box-container">
          <CustomTable data={stationsData} columns={columns}></CustomTable>
        </div>
      </div>
      <Footer/>
      <div>
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>{isNew ? "Add " : "Update "} Station</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <Form>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Station Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Station Name"
                  defaultValue={eachStation.StationName}
                  onChange={(e) => onChange(e, "StationName")}
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="formBasicPassword">
                <Form.Label>Station Code</Form.Label>
                <Form.Control
                  type="text"
                  disabled={isNew ? false : true}
                  placeholder="Enter Station Code"
                  defaultValue={eachStation.StationCode}
                  onChange={(e) => onChange(e, "StationCode")}
                />
              </Form.Group>
            </Form>
          </Modal.Body>

          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button variant="primary" type="submit" onClick={addStation}>
              {isNew ? "Save" : "Update"}
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
}
