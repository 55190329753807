import React, {useEffect,useState} from "react";
import CustomTable from "../TableComponent/CustomTable.component";
import Header from "../header/Header";
import Footer from "../footer/Footer";
import "./Refunds.css";
import {convertDateToMilliseconds, convertDateToIST,convertDeliveryDate,convertBookingDate} from '../Utility/Helper';
import axios from "axios";
import Button from "react-bootstrap/Button";
import Swal from 'sweetalert2';

export default function Refunds() {
    const [refundsData,setRefundsData] = useState([]);

    useEffect(() => {
       // setRefundsData([]);
       getOnlineOrders();
      }, []);

      function getOnlineOrders() {
        axios
          .get(process.env.REACT_APP_BASE_URL + `/order/getOnlineOrders/`)
          .then((response) => {
            if (response.data.success) {
              console.log(response.data.body);
              response.data.body.forEach((element) => {
                element.Booking_Date =  convertBookingDate(element.Booking_Date);
                element.Delivery_Date =  convertDeliveryDate(element.Delivery_Date);
                element.Order_Status_Id.OrderStatus =  element.Order_Status_Id.OrderStatus.replace('ORDER_', '');
                element.Total_Amount.$numberDecimal =  "Rs."+element.Total_Amount.$numberDecimal;

                
              });
              setRefundsData(response.data.body);
            }
          })
          .catch((error) => {
            console.error("There was an error!", error);
          });
      }


      function UpdateOrder(Order_Id) {
        Swal.fire({
          title: "Are you sure?",
          text: "You won't be able to Refund this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes"
        }).then((result) => {
          if (result.isConfirmed) {
            axios
          .get(process.env.REACT_APP_BASE_URL + `/order/updateOrderRefund/${Order_Id}`)
          .then((response) => {
            if (response.data.success) {
              Swal.fire({
                position: "center",
                icon: "success",
                title: "Successfully Refunded.",
                showConfirmButton: false,
                timer: 3500
              });
              getOnlineOrders(); 
            }
          })
          .catch((error) => {
            console.error("There was an error!", error);
          });
          }
        });
        
      }
    
      const columns = [
        {
          dataField: "_id",
          text: "Id",
          hidden: true,
        },
        {
          dataField: "Order_Id",
          text: "Order Id",
          classes: 'order_id mobile-td',
          sort: true,
          headerStyle: (column, colIndex) => {
            return { width: "160px" };
          },
        },
        {
          dataField: "PNR",
          text: "PNR #",
          classes: 'consumer_name mobile-td',
          sort: true,
          headerStyle: (column, colIndex) => {
            return { width: "110px" };
          },
        }, 
        {
          dataField: "Consumer_Name",
          text: "Consumer Name",
          classes: 'consumer_name mobile-td',
          sort: true,
          headerStyle: (column, colIndex) => {
            return { width: "180px" };
          },
        }, 
        {
          dataField: "User_Id.mobileNumber",
          text: "Mobile Number",
          classes: 'mobile mobile-td',
          sort: true,
          headerStyle: (column, colIndex) => {
            return { width: "200px" };
          },
        },
        {
          dataField: "User_Id.emailID",
          text: "Email",
          classes: 'email mobile-td',
          sort: true,
          headerStyle: (column, colIndex) => {
            return { width: "250px" };
          },
        },
        {
          dataField: "Total_Amount.$numberDecimal",
          text: "Total Amt (Rs.)",
          classes: 'amt mobile-td',
          sort: true,
          headerStyle: (column, colIndex) => {
            return { width: "140px" };
          },
        },
        {
          dataField: "Booking_Date",
          text: "Booking Date",
          classes: 'booking_date mobile-td',
          sort: true,
          headerStyle: (column, colIndex) => {
            return { width: "180px" };
          },
        },
        {
          dataField: "Delivery_Date",
          text: "Delivery Date",
          classes: 'delivery_date mobile-td',
          sort: true,
          headerStyle: (column, colIndex) => {
            return { width: "180px" };
          },
        },
        {
          dataField: "Vendor_Id.VendorName",
          text: "Vendor Name",
          classes: 'vendor_name mobile-td',
          sort: true,
          headerStyle: (column, colIndex) => {
            return { width: "180px" };
          },
        },
        {
          dataField: "Outlet_Id.OutletName",
          text: "Outlet Name",
          classes: 'vendor_name mobile-td',
          sort: true,
          headerStyle: (column, colIndex) => {
            return { width: "160px" };
          },
        },
        {
          dataField: "Order_Status_Id.OrderStatus",
          text: "Order Status",
          classes: 'status mobile-td',
          sort: true,
          headerStyle: (column, colIndex) => {
            return { width: "160px" };
          },
        },
        {
          dataField: "Payment_Mode",
          text: "Payment Mode",
          classes: 'payment_mode mobile-td',
          sort: true,
          headerStyle: (column, colIndex) => {
            return { width: "160px" };
          },
        },
        {
          dataField: "_id",
          text: "Actions",
          formatter: ActiveFormatter,
          headerStyle: (column, colIndex) => {
            return { width: "120px" };
          },
        },
      ];

      function ActiveFormatter(cell, row) {
        return (
          <>
            <Button variant="primary" type="submit" onClick={() => {
            UpdateOrder(row.Order_Id)
            }}>Refund</Button>
          </>
        );
      }
  return (
    <>
      <Header/>
      <div className="page-container">
        <div className="page-header">
          <h4 className="page-title">Refunds</h4> 
          
        </div>
        <div className="box-container">
          <CustomTable data={refundsData} columns={columns}></CustomTable>
        </div>
      </div>
      <Footer/>


    </>
  );
}
