import moment from 'moment-timezone';

export function convertDateToMilliseconds(inputDate) {
    return new Date(inputDate).toLocaleString("en-US", { timeZone: 'Asia/Calcutta' });
  }

export function formatTime(timeString) {
    const [hourString, minute] = timeString.split(":");
    const hour = +hourString % 24;
    return (hour % 12 || 12) + ":" + minute + (hour < 12 ? " AM" : " PM");
 }

 export function convertDeliveryDate(date){
    if(date)
    {
       let splitArr = date.split(" ");
       let splitDate = splitArr[0].split("-");
       let localDate = splitDate[2] +"/"+ splitDate[1]+"/"+splitDate[0]+"  "+formatTime(splitArr[1]);
       return localDate;
    }
    return date;
 }

  export function convertDateToIST(inputDate) {
    console.log(inputDate);
    console.log(moment(inputDate).tz("Asia/Calcutta").format("YYYY-MM-DD HH:mm:ss"));
    return moment(inputDate).tz("Asia/Calcutta").format("YYYY-MM-DD HH:mm:ss");   
  }

  export function convertBookingDate(date){
        if(date)
        {
            return  moment(date).format('DD/MM/YYYY hh:mm A');
            let n = date.toLocaleString([], {
                hour: '2-digit',
                minute: '2-digit'
            });         
            console.log(n);
           let splitArr = date.split(" ");
           let splitDate = splitArr[0].split("-");
           let localDate = splitDate[2] +"/"+ splitDate[1]+"/"+splitDate[0]+"  "+n;
           return localDate;
        }
        return date;
       
  }

  export function getAllMonths()
  {
     return moment.months();
  }