import React, { useEffect, useState } from "react";
//import CustomTable from "../TableComponent/CustomTable.component";
import axios from "axios";
/*
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import "./Vendor.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTrash,
  faEdit,
  faCircleCheck,
  faBan,
  faKey,
} from "@fortawesome/free-solid-svg-icons";*/

import "./OutletUser.css";
import { Form, Button, Container } from 'react-bootstrap';
import { Routes, Route, Navigate, Link, useNavigate } from "react-router-dom";

import Header from "../header/Header";
import Footer from "../footer/Footer";

const OutletUserAdd = () => {
  const [loadVendors, setLoadVendors] = useState([]);
  const [outlets, setOutlets] = useState([]);
  const [formData, setFormData] = useState({
    Outlet_Id: '',
    VendorId: '',
    name: '',
    password: '',
    confirmPassword: '',
    mobileNumber: '',
    emailID: ''
  });


  useEffect(() => {

    getAllVendors();
  }, []);

  const [errors, setErrors] = useState({
    Outlet_Id: '',
    email: '',
    VendorId: '',
    name: '',
    password: '',
    confirmPassword: '',
    mobile: ''
  });


  function getAllVendors() {
    axios
      .get(process.env.REACT_APP_BASE_URL + "/vendors/getAllVendors")
      .then((response) => {
        if (response.data.success) {
          setLoadVendors(response.data.body);
        }
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }

  function getOutletsByVendorId(vendorId) {
    //console.log("ENV URL", process.env.REACT_APP_BASE_URL)
    //const user = JSON.parse(localStorage.getItem("user"));
    axios
      .get(process.env.REACT_APP_BASE_URL + `/Outlets/getOutletsByVendorId/${vendorId}`)
      .then((response) => {
        if (response.data.success) {
          setOutlets(response.data.body);
        }
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }

  const checkValidation = () => {
    const newErrors = {};
    const indianMobileNumberRegex = /^[6-9]\d{9}$/;
    const strongPasswordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%^*?&])[A-Za-z\d@$!%^*?&]{8,15}$/;
    const name_regex = /^[A-Za-z0-9\s]+$/

    const emailRex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!formData.emailID) {
      newErrors.email = 'Please enter email address';
    }

    console.log("validation block")
    if (!formData.VendorId) {
      newErrors.VendorId = 'Please select vendor';
    }
    if (!formData.Outlet_Id) {
      newErrors.Outlet_Id = 'Please select outlet';
    }
    if (formData.emailID && !emailRex.test(formData.emailID)) {
      newErrors.email = 'Invalid email address';
    }

    if (!formData.mobileNumber) {
      newErrors.mobile = 'Please enter mobile number';
    }

    if (formData.mobileNumber != '' && !indianMobileNumberRegex.test(formData.mobileNumber)) {
      newErrors.mobile = 'Invalid mobile number';
    }

    if (!formData.password) {
      newErrors.password = "Please enter password";
    }

    if (formData.password != '' && !strongPasswordRegex.test(formData.password)) {
      newErrors.password = 'Password must be at least 8 characters long, one upper case letter, one lower case letter, one numerical digit and one special character';
    }

    if (!formData.confirmPassword) {
      newErrors.confirmPassword = 'Please enter confirm password';
    }

    if (formData.confirmPassword != '' && formData.confirmPassword !== formData.password) {
      newErrors.confirmPassword = 'Passwords do not match';
    }

    if (!formData.name) {
      newErrors.name = 'Please enter  name';
    }

    if (formData.name != '' && !name_regex.test(formData.name)) {
      newErrors.name = 'Please enter valid name';
    }

    setErrors(newErrors);
    console.log("newErrors", newErrors);

    // If there are no errors, you can proceed with form submission
    if (Object.keys(newErrors).length === 0) {
      //setIsLoading(false);
      // Perform your form submission logic here
      //console.log('Form submitted successfully');

      console.log("Form data", formData)

      return true;
    } else {
      return false;
    }
  };

  const [validated, setValidated] = useState(false);

  const navigate = useNavigate();

  const handleNavigation = () => {
    navigate('/OutletUsers');
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    console.log("name:", name, "value:", value)
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    if (name === 'VendorId') {
      getOutletsByVendorId(value);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    /*const form = e.currentTarget;
    if (form.checkValidity()) {
      console.log('Form submitted successfully:', formData);
      // You can perform your form submission logic here
    } else {
      e.stopPropagation();
    }

    setValidated(true);*/
    // If there are no errors, you can proceed with form submission
    if (checkValidation()) {
      //setIsLoading(false);
      // Perform your form submission logic here
      console.log('Form submitted successfully');

      axios
        .post(process.env.REACT_APP_BASE_URL + "/OutletUser/create", formData)
        .then((response) => {
          if (response.data.success) {
            console.log("Vendor create api response", response.data)
            handleNavigation();
          } else {
            //alert(JSON.stringify(response.data.error));
            console.log("Error respone", response.data)
            //handleClose();
          }
        })
        .catch((error) => {
          console.error("There was an error!", error);
        });


    }

  };



  return (

    <>
      <Header />
      <div className="page-container">
        <div className="page-header">
          <h4 className="page-title">Add Outlet User</h4>

        </div>
        <div className="box-container">
          <Form onSubmit={handleSubmit} className="add-outlet-user-form">
            <Form.Group className="w-50" controlId="VendorId">
              <Form.Label>Vendor</Form.Label>
              <Form.Select name="VendorId" aria-label="Default select example" onChange={handleChange} onBlur={checkValidation} required>
                <option value="">Select Vendor</option>
                {loadVendors.map((i) => (
                  <option value={i.user_Id._id} key={i._id}>
                    {i.VendorName}
                  </option>
                ))}
              </Form.Select>
              <span className="error">{errors.station}</span>
              <Form.Control.Feedback type="invalid">
                Please select the vendor.
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="w-50" controlId="Outlet_Id">
              <Form.Label>Outlets <span className="error">*</span></Form.Label>
              <Form.Select
                class="form-select form-select-lg mb-3"
                aria-label=".form-select-lg example"
                onChange={handleChange}
                name="Outlet_Id"

              >
                <option value="">Select Outlet</option>
                {outlets ? outlets.map((i) => (
                  <option value={i._id} key={i._id}>
                    {i.OutletName}
                  </option>
                )) : null}

              </Form.Select>
            </Form.Group>
            <Form.Group className="w-50" controlId="Name">
              <Form.Label>Name</Form.Label>
              <Form.Control
                
                type="text"
                name="name"
                value={formData.VendorName}
                onChange={handleChange}
                onBlur={checkValidation}
              />
              <span className="error">{errors.name}</span>
              <Form.Control.Feedback type="invalid">
                Please provide a valid name.
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="w-50" controlId="password">
              <Form.Label>Password</Form.Label>
              <Form.Control
                
                type="password"
                name="password"
                value={formData.vendorPassword}
                onChange={handleChange}
                onBlur={checkValidation}
              />
              <span className="error">{errors.password}</span>
              <Form.Control.Feedback type="invalid">
                Please provide a valid password.
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="w-50" controlId="confirmPassword">
              <Form.Label>Confirm Password</Form.Label>
              <Form.Control
                
                type="password"
                name="confirmPassword"
                value={formData.confirmPassword}
                onChange={handleChange}
                onBlur={checkValidation}
              />
              <span className="error">{errors.confirmPassword}</span>
              <Form.Control.Feedback type="invalid">
                Please provide a valid confirm password.
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="w-50" controlId="email">
              <Form.Label>Email</Form.Label>
              <Form.Control
                
                type="email"
                name="emailID"
                value={formData.emailID}
                onChange={handleChange}
                onBlur={checkValidation}
              />
              <span className="error">{errors.email}</span>
              <Form.Control.Feedback type="invalid">
                Please provide a valid email.
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="w-50" controlId="MobileNumber">
              <Form.Label>Mobile</Form.Label>
              <Form.Control
                type="number"
                name="mobileNumber"
                value={formData.MobileNumber}
                onChange={handleChange}
                onBlur={checkValidation}
              />
              <span className="error">{errors.mobile}</span>
              <Form.Control.Feedback type="invalid">
                Please provide a valid mobile.
              </Form.Control.Feedback>
            </Form.Group>

            <div className="mt-3"><Button className="xs-btn-block" type="submit">Submit</Button></div>
          </Form>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default OutletUserAdd;