import React, {useEffect,useState} from "react";
import CustomTable from "../TableComponent/CustomTable.component";
import axios from "axios";

export default function Users() {
  const [usersData,setUsersData] = useState([]);

    useEffect(() => {
        setUsersData([]);
        getOutUsers();
      }, []);

      function getOutUsers() {        
        axios
          .get(process.env.REACT_APP_BASE_URL +"/OutletUser/getAllOutletUsers")
          .then((response) => {
            if (response.data.success) {
              setUsersData(response.data.body);
            }
          })
          .catch((error) => {
            console.error("There was an error!", error);
          });
      }

    const columns = [
        {
          dataField: "_id",
          text: "Id",
          hidden: true,
        },
        {
          dataField: "Outlet_Id.OutletName",
          text: "Outlet Name",
          sort: true,
          headerStyle: (column, colIndex) => {
            return { width: "100px", textAlign: "center" };
          },
        },
        {
          dataField: "name",
          text: "Name",
          sort: true,
          headerStyle: (column, colIndex) => {
            return { width: "100px", textAlign: "center" };
          },
        },
        {
          dataField: "emailID",
          text: "Email",
          sort: true,
          headerStyle: (column, colIndex) => {
            return { width: "100px", textAlign: "center" };
          },
        },
        {
          dataField: "mobileNumber",
          text: "Mobile Number",
          sort: true,
          headerStyle: (column, colIndex) => {
            return { width: "150px", textAlign: "center" };
          },
        },
        {
          dataField: "Message",
          text: "Message",
          sort: true,
          headerStyle: (column, colIndex) => {
            return { width: "100px", textAlign: "center" };
          },
        },
        {
          dataField: "Date",
          text: "Date",
          sort: true,
          headerStyle: (column, colIndex) => {
            return { width: "150px", textAlign: "center" };
          },
        },
      ];
  return (
    <>
         <div style={{ backgroundColor: "transparent", height:"100vh",overflowY:"scroll" }}>
        <div className="row mainHeader">
          <div className="col-md-4">
            <h4 className="labeltextcolor">Users</h4>
          </div>
          <div className="col-md-8" style={{ textAlign: "right" }}>
           
          </div>
        </div>
        <div className="col-md-12 mainHeader">
          <CustomTable data={usersData} columns={columns}></CustomTable>
        </div>
      </div>
    </>
  );
}
