import "bootstrap/dist/css/bootstrap.min.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.css";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import React, { useEffect, useState } from "react";
import "./CustomTable.css";
import ToolkitProvider, {
  Search,
  CSVExport,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "react-bootstrap";
import {
 faTrashCan
} from "@fortawesome/free-solid-svg-icons";

export default function CustomTable(props) {
  const [data, setData] = useState([]);
  const [columns, setColumns] = useState([]);
  const { SearchBar } = Search;
  const { ExportCSVButton } = CSVExport;

  useEffect(() => {
    setData(props.data);
    setColumns(props.columns);
  }, [props.data]);


  const handleDeleteAll = () => {
    // Call the callback function to delete all menu items
    if (props.onDeleteAll) {
      props.onDeleteAll();
    }
  };

  const pagination = paginationFactory({
    page: 1,
    sizePerPage: 5,
    lastPageText: ">>",
    firstPageText: "<<",
    nextPageText: ">",
    prePageText: "<",
    showTotal: true,
    alwaysShowAllBtns: true,
    onPageChange: function (page, sizePerPage) {
      console.log("page", page);
      console.log("sizePerPage", sizePerPage);
    },
    onSizePerPageChange: function (page, sizePerPage) {
      console.log("page", page);
      console.log("sizePerPage", sizePerPage);
    },
  });
  const rowClasses = (row, rowIndex) => {
    let classes = null;

    if (rowIndex % 2 == 0) {
      classes = "evenClass";
    } else {
      classes = "oddClass";
    }

    return classes;
  };
  if (data.length > 0) {
    return (
      <div className="row">
        <div className="col-md-12">
          <ToolkitProvider
            bootstrap4
            keyField="_id"
            data={data}
            columns={columns}
            search
            exportCSV={{
              fileName: "custom.csv",
              separator: "|",
              ignoreHeader: true,
              noAutoBOM: false,
              blobType: "text/csv;charset=ansi",
            }}
          >
            {(props) => (
              <>
                <div className="row">
                  <div className="page-header menu-items-header">
                  <div className="col-md-6">
                    <SearchBar
                      {...props.searchProps}
                      className="custome-search-field"
                      delay={1000}
                      placeholder="Search"
                    />
                  </div>
                  {/* <div className="col-md-6" style={{ textAlign: "right" }}>
                    <ExportCSVButton {...props.csvProps}>
                      Export CSV!!
                    </ExportCSVButton>
                  </div> */}
                 
                  <Button onClick={handleDeleteAll}
            className="float-right">
            <FontAwesomeIcon icon={faTrashCan} style={{marginRight:"5px"}}/>
            DeleteAll
          </Button>
          </div>
            
                <div className="col-md-12">
                  <BootstrapTable
                    pagination={pagination}
                    headerClasses="header-class"
                    rowClasses={rowClasses}
                    {...props.baseProps}
                  />
                </div>
                </div>
              </>
            )}
          </ToolkitProvider>
        </div>
      </div>
    );
  }
  else{
    return ( <div style={{
      marginTop: '114px'}}><h3 style={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
  }}><p>No data found</p></h3></div>)
  }
}
