
import React from "react";

export default function Footer() {
    
  return (
    <>
        <footer className="footer">
            Copyright © Train Dhaba 2023. All Right Reserved - by Aagama
        </footer>
    </>
  );
}

