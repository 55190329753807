import React, { useEffect, useState } from "react";
import CustomTable from "../TableComponent/CustomTable.component";
import Header from "../header/Header";
import Footer from "../footer/Footer";
import "./Invoices.css";
import { convertDateToMilliseconds, convertDateToIST, convertDeliveryDate, convertBookingDate } from '../Utility/Helper';
import axios from "axios";
import Button from "react-bootstrap/Button";
import { Form, Container } from 'react-bootstrap';
import Swal from 'sweetalert2';
import Table from 'react-bootstrap/Table';
import { getAllMonths } from '../Utility/Helper'
import html2canvas from 'html2canvas';
import { jsPDF } from "jspdf";
export default function Invoices() {
  const [invoicesData, setInvoicesData] = useState([]);
  const [show, setShow] = useState(false);
  const [vendorId, setvendorId] = useState("");
  const [loadVendors, setLoadVendors] = useState([]);
  const [months, setMonths] = useState([]);
  const [years, setYears] = useState([]);
  const [formData, setFormData] = useState({
    usertype:'Admin',
    vendorId: '',
    year: '',
    month: '',
  });
  const [vendorIdErr, setVendorIdErr] = useState("")
  const [monthIdErr, setMonthIdErr] = useState("")
  const [yearIdErr, setYearIdErr] = useState("")
  const [invoiceInfo, setInvoiceInfo] = useState({});
  var path = process.env.PUBLIC_URL;
  var image = "/logo192.png";
  useEffect(() => {
    setMonths(getAllMonths());
    getYears();
    setInvoicesData([]);
    getAllVendors();


  }, []);
  function getYears() {
    let minOffset = 0, maxOffset = 10;
    let thisYear = (new Date()).getFullYear();
    let allYears = [];
    for (let x = 0; x <= maxOffset; x++) {
      allYears.push(thisYear - x)
    }
    setYears(allYears);
  }
  function getAllVendors() {
    axios
      .get(process.env.REACT_APP_BASE_URL + "/vendors/getAllVendors")
      .then((response) => {
        if (response.data.success) {
          setLoadVendors(response.data.body);
        }
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }
  function generateInvoice() {
    setShow(false);
    let isValid = true;
    for (const [name, value] of Object.entries(formData)) {
      if (!checkValidation(name, value)) {
        isValid = false; // Set isValid to false if any field fails validation
        // Exit the loop since the form is already considered invalid
      }

    }
    console.log("isvalid-vend", isValid, formData)

    if (isValid) {
      setFormData({
        ...formData,
        ['usertype']: 'Admin'
      });
      axios
        .post(process.env.REACT_APP_BASE_URL + "/Invoice/generateInvoice", formData)
        .then((response) => {
          console.log(response);
          console.log("Orderdeliverydetails",response.data.body.Orderdeliverydetails);
          if (response.data.success) {
            let invoicedetailsInfo = {
              Month: response.data.body.Month,
              Year: response.data.body.Year,
              Invoice_No: response.data.body.invoiceNo,
              InvoiceDate: response.data.body.InvoiceDate,
              AccountingDate: response.data.body.AccountingDate,
              DueDate: response.data.body.dueDate,
              Discountpercentage: response.data.body.Discountpercentage,
              Vendoramtpercentage: response.data.body.vendoramtpercentage,
              Tbamtpercentage: response.data.body.tbamtpercentage,
              COD_Orders_Total: response.data.body.COD_Orders_Total,
              Prepaid_Orders_Total: response.data.body.Prepaid_Orders_Total,
              Total_Orders_Count: response.data.body.total_Orders_Count,
              COD_Total: response.data.body.COD_Total,
              Prepaid_Total: response.data.body.prepaid_Total,
              Total_Base_Price: response.data.body.total_Base_Price,
              COD_Disc: response.data.body.COD_Disc,
              Prepaid_Disc: response.data.body.prepaid_Disc,
              Total_Disc: response.data.body.total_Disc,
              COD_GST: response.data.body.COD_GST,
              Prepaid_GST: response.data.body.prepaid_GST,
              Total_GST_Price: response.data.body.total_GST_Price,
              Total_COD_amount: response.data.body.total_COD_amount,
              Total_Prepaid_amount: response.data.body.total_Prepaid_amount,
              Total_Amount: response.data.body.total_Amount,
              VendorCODAmount: response.data.body.vendorCODAmount,
              VendorPrepaidAmount: response.data.body.vendorPrepaidAmount,
              Totalvendor_Amount: response.data.body.totalvendor_Amount,
              TtbCODAmount: response.data.body.tbCODAmount,
              TbPrepaidAmount: response.data.body.tbPrepaidAmount,
              Totalprepaid_Amount: response.data.body.totalprepaid_Amount,
              PaytoVendorAmount: response.data.body.paytoVendorAmount,
              PaytoTBAmount: response.data.body.paytoTBAmount,              
              VendorName: response.data.body.vendorData.VendorName,
              AccountNo: response.data.body.vendorData.AccountNO,
              AccountName: response.data.body.vendorData.AccountName,
              IFSCCode: response.data.body.vendorData.IFSCCode,
              AccountType: response.data.body.vendorData.AccountType,
              OnlineTransactionNo: response.data.body.vendorData.OnlineTransactionNo,
              MobileNumber: response.data.body.vendorData.MobileNumber,
              Address: response.data.body.vendorData.Address,
              VendorGST: response.data.body.vendorData.GST,
              Orderdeliverydetails:response.data.body.Orderdeliverydetails
  
            };
            setInvoiceInfo(invoicedetailsInfo);
            if(invoicedetailsInfo.COD_Orders_Total>0 || invoicedetailsInfo.Prepaid_Orders_Total>0){
            setShow(true);}           
          } else {
            //alert(JSON.stringify(response.data.error));
            console.log("Error respone", response.data)
            //handleClose();
          }
        })
        .catch((error) => {
          console.error("There was an error!", error);
        });
       
     

    }
    console.log(vendorId);
  }
  const checkValidation = (name, value) => {

    switch (name) {
      case 'vendorId':
        setVendorIdErr(value === '0' || value === '' ? 'Please select the Vendor' : '');
        return !(value === '0' || value === '');
      case 'month':
        setMonthIdErr(value === '0' || value === '' ? 'Please select the month' : '');
        return !(value === '0' || value === '');
      case 'year':
        setYearIdErr(value === '0' || value === '' ? 'Please select the year' : '');
        return !(value === '0' || value === '');
      default:
        return true;
    }
  };

  const handleChange = e => {
    //setvendorId(e.target.value);
    console.log(e.target.value);
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });

  };
  const Print = () =>{     
    //console.log('print');  
    let printContents = document.getElementById('printablediv').innerHTML;
    let originalContents = document.body.innerHTML;
    document.body.innerHTML = printContents;
    window.print();
   document.body.innerHTML = originalContents; 
  // const input = document.getElementById('printablediv');
  // html2canvas(input)
  //   .then((canvas) => {
  //     const imgData = canvas.toDataURL('image/png');
  //     const pdf = new jsPDF();
  //     pdf.addImage(imgData, 'JPEG', 0, 0);
  //     // pdf.output('dataurlnewwindow');
  //     pdf.save("download.pdf");
  //   })
  // ;
  }

  return (
    <>
      <Header />
      <div className="row">
        <div className="col-sm-3 mb-3">
          <Form.Group controlId="VendorId">
            <Form.Label>Vendor <span className="error">*</span></Form.Label>
            <Form.Select
              onChange={handleChange}
              name="vendorId"
            >
              <option value="">Select Vendor</option>
              {loadVendors ? loadVendors.map((i) => (
                <option value={i._id} key={i._id}>
                  {i.VendorName}
                </option>
              )) : null}

            </Form.Select>
            <span className="error">{vendorIdErr}</span>
            <Form.Control.Feedback type="invalid">
              Please select a valid vendor.
            </Form.Control.Feedback>
          </Form.Group>
        </div>
        <div className="col-sm-3 mb-3">
          <Form.Group controlId="month">
            <Form.Label>Month <span className="error">*</span></Form.Label>
            <Form.Select
              onChange={handleChange}
              name="month"

            >
              <option value="">Select Month</option>
              {months ? months.map((i) => (
                <option value={i} key={i}>
                  {i}
                </option>
              )) : null}

            </Form.Select>
            <span className="error">{monthIdErr}</span>
            <Form.Control.Feedback type="invalid">
              Please select a valid vendor.
            </Form.Control.Feedback>
          </Form.Group>
        </div>
        <div className="col-sm-3 mb-3">
          <Form.Group controlId="year">
            <Form.Label>Year <span className="error">*</span></Form.Label>
            <Form.Select
              onChange={handleChange}
              name="year"
            >
              <option value="">Select Year</option>
              {years ? years.map((i) => (
                <option value={i} key={i}>
                  {i}
                </option>
              )) : null}

            </Form.Select>
            <span className="error">{yearIdErr}</span>
            <Form.Control.Feedback type="invalid">
              Please select a valid vendor.
            </Form.Control.Feedback>
          </Form.Group>
        </div>
        <div className="col-sm-2 mt-4">
          <Button type="submit" className="btn-block w-100" onClick={() => generateInvoice()}>Generate</Button>
        </div>
      </div>
      <hr />
      <div className='row'>
      <div class="offset-md-10">
      {show ?  <button type="button" onClick={Print} > Print</button> : null}
      </div>
      </div>
      {show ? <div id='printablediv' className="page-container">
        <div className="">
          <center> <h4 className="page-title"><u>TRAIN DHABA INVOICE FOR {invoiceInfo.Month} {invoiceInfo.Year}</u></h4> </center>
          <center> <label><u>(Generated By Train Dhaba)</u></label> </center>

        </div>
        <div className="box-container mt-5" style={{fontSize: 'medium'}}>
          <div class="row justify-content-center">
          
            <div className="col-sm-2">
              <label><u className="underline">Invoice No:</u></label><br />
              <label><u className="underline">Invoice Date:</u></label><br />
              <label><u className="underline">Accounting Date:</u></label><br />
              <label><u className="underline">Due Date:</u></label>
            </div>

            <div className="col-sm-3">
              <label><u className="underline">{invoiceInfo.Invoice_No}</u></label><br />
              <label><u className="underline">{invoiceInfo.InvoiceDate}</u></label><br />
              <label><u className="underline">{invoiceInfo.AccountingDate}</u></label><br />
              <label><u className="underline">{invoiceInfo.DueDate}</u></label><br />
            </div>

            <div class="col-sm-3">
            <img src={path + image} className='center' alt="TrainDhaba.logo" style={{width: '150px',
  height: '110px',marginLeft: '115px'}}/>
              <img src=""></img>
            </div>
            
          </div>
          <br/>
          <br/>
          <div class="row justify-content-center" >
            
            <div class="col-sm-4">
              <label><u className="underline">BILLED BY</u></label> <br />
              <label>Train Dhaba</label> <br />
              <p>Address</p> 
              <p>#2 Floor, North East Avenue Building, Plot 470
& 471 Road No.26A, Ayyappa Society
Madhapur, Hyderabad – 500 081, India</p>
              <label>GST: 36AAECE9757F1ZB</label>
            </div>

            <div class="col-sm-4">
              <label><u className="underline">BILLED TO</u></label> <br />
              <label>{invoiceInfo.VendorName}</label> <br />
              <p>Address</p> 
              <p>{invoiceInfo.Address}</p>
              <label>{invoiceInfo.VendorGST}</label>
            </div>
            
          </div>
<br/>
<br/>
<div class="row justify-content-center" >

<div className="col-sm-8">
<Table striped  hover>
  <tr style={{backgroundColor:'#ff5c01'}}>
    <th>S.No</th>
    <th>ORDER SUMMARY</th>
    <th>ORDERS COUNT</th>
	<th>TOTAL BASE PRICE</th>
  <th>DISCOUNT PRICE</th>
    <th>GST ON BASE PRICE</th>
    <th>TOTAL AMOUNT</th>
	<th>VENDOR AMOUNT </th>
  <th>TRAIN DHABA AMOUNT </th>
  </tr>
  <tr>
  <td>1</td>
    <td>Cash On Delivery</td>
    <td>{invoiceInfo.COD_Orders_Total}</td>
	<td>₹ {invoiceInfo.COD_Total}</td>
  <td>₹ {invoiceInfo.COD_Disc}</td>
    <td>₹ {invoiceInfo.COD_GST}</td>
    <td>₹ {invoiceInfo.Total_COD_amount}</td>
    <td>₹ {invoiceInfo.VendorCODAmount}</td>
    <td>₹ {invoiceInfo.TtbCODAmount}</td>
  </tr>
  <tr>
  <td>2</td>
    <td>Pre-Paid</td>
    <td>{invoiceInfo.Prepaid_Orders_Total}</td>
	<td>₹ {invoiceInfo.Prepaid_Total}</td>
  <td>₹ {invoiceInfo.Prepaid_Disc}</td>
    <td>₹ {invoiceInfo.Prepaid_GST}</td>
    <td>₹ {invoiceInfo.Total_Prepaid_amount}</td>
    <td>₹ {invoiceInfo.VendorPrepaidAmount}</td>
	<td>₹ {invoiceInfo.TbPrepaidAmount} </td>
  </tr>
  <tr className="mt-2">
  <td></td>
    <td style={{fontWeight: "bold"}}>Total Monthly</td>
    <td style={{fontWeight: "bold"}}>{invoiceInfo.Total_Orders_Count}</td>
  <td style={{fontWeight: "bold"}}>₹ {invoiceInfo.Total_Base_Price}</td>
  <td style={{fontWeight: "bold"}}>₹ {invoiceInfo.Total_Disc}</td>
  <td style={{fontWeight: "bold"}}>₹ {invoiceInfo.Total_GST_Price}</td>
  <td style={{fontWeight: "bold"}}>₹ {invoiceInfo.Total_Amount}</td>
  <td style={{fontWeight: "bold"}}>₹ {invoiceInfo.Totalvendor_Amount}</td>
  <td style={{fontWeight: "bold"}}>₹ {invoiceInfo.Totalprepaid_Amount}</td>
  </tr>
  </Table>
  </div>
  
 
  </div>
  <div class="row justify-content-center" >
  <div className="col-sm-8"> <hr/></div>
  </div>
  <br/>
  <br/>
  
<br/>
  <div class="row justify-content-center" >
  <div className="col-sm-8">
  
  <label>Total Train Dhaba Amount collected by Vendor under COD (BasePrice*0.3+GST-Discounts) =</label> <label> ₹ {invoiceInfo.TtbCODAmount}</label> <br />
  <label>Total Vendor amount collected by Train Dhaba under PREPAID (BasePrice*0.7) =</label> <label>  ₹ {invoiceInfo.VendorPrepaidAmount}</label>  <br />
  <label>Total Amount {invoiceInfo.VendorName} to pay to Train Dhaba =</label> <label>  ₹ {invoiceInfo.PaytoTBAmount}</label>  <br />
   <br />
  <label>Total Invoice Amount	 =</label> <label> ₹ {invoiceInfo.PaytoTBAmount}</label> 
  </div>
  </div>
  <br/>
  <br/>
  <br/>
  <div class="row justify-content-center">
  <div class="col-sm-3">
  <label>A/C No</label> <br />
  <label>Account Name:</label> <br />
  <label>IFSC Code:</label> <br />
  <label>Account Type:</label> <br /><br />
  <b><p>PhonePe/Gpay/Paytm:</p></b>
  </div>
  
  <div class="col-sm-3">
  <label>630605121527</label> <br />
  <label>ESEM ECOFRI PRIVATE LIMITED</label> <br />
  <label>ICIC0006306</label> <br />
  <label>Current Account</label> <br /><br />
  <b><p>9494942103 (M BALA KRISHNA)</p></b>
  </div>
  
  <div class="col-sm-2">
  <img src=""></img>
  <label style={{marginTop: "104px",  marginLeft: "60px"}}>Surya Teja - COO</label>
  </div>
  
  
  </div>
  <div class="row justify-content-center">
  <div class="col-sm-8">
    
  <p>Disclaimer:<br />
  1. In case of any issues in the invoice, revert back to same <br />
  2. Please make the payment by due date <br />
  3. All the orders history is attached along with the invoice</p>
    </div>
  </div>
  {/* <div class="row justify-content-center">
  <div class="col-sm-8">
    
  <table>
                <tr>
                    <th>Order Id</th>
                    <th>PNR #</th>
                    <th>Consumer Name</th>
                    <th>Station Name</th>
                    <th>Status</th>
                    <th>Total Amt (Rs.)</th>
                </tr>
                {invoiceInfo.Orderdeliverydetails.map((val, key) => {
                    return (
                        <tr key={key}>
                           <td>{val.Order_Id}</td>
                            <td>{val.PNR}</td>
                            <td>{val.Consumer_Name}</td>
                            <td>{val.StationName}</td>
                            <td>{val.Payment_Mode}</td>
                            {val.OrderItems.map((val, key) => {
                    return (                       
                            <td>{val.Base_Price}</td>                     
                    )
                })}
                           
                        </tr>
                    )
                })}
            </table>
    </div>
  </div> */}
 
          </div>

          
          </div> :  <div class="row justify-content-center">
  <div class="col-sm-2">  
  </div>  
  <div class="col-sm-3">
  <label>No data found.</label> <br />  <br /> 
  </div>  
  <div class="col-sm-2"> 
  </div> 
  
  </div>}

      <Footer />


    </>
  );
}
