import React, { useEffect, useState } from "react";
import CustomTable from "../TableComponent/CustomTable.component";
import axios from "axios";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import "./OutletUser.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTrash,
  faEdit,
  faCircleCheck,
  faBan,
  faKey,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from 'react-router-dom';
import Header from "../header/Header";
import Footer from "../footer/Footer";

export default function OutletUsers() {
  const [OutletUsersData, setOutletUsersData] = useState([]);
  const [eachVendor, setEachVendor] = useState({});
  const [show, setShow] = useState(false);
  const [isNew, setIsNew] = useState(true);
  const [defaultValue, setDefaultValue] = useState("");

  const navigate = useNavigate();

  const handleNavigation = () => {
    navigate('/AddOutletUser');
  };

  useEffect(() => {
    getAllOutletUsers();
  }, []);
  function AddNewVendor() {
    setShow(true);
    setIsNew(true);
    setEachVendor({});
  }
  function getAllOutletUsers() {
    axios
      .get(process.env.REACT_APP_BASE_URL + "/OutletUser/getAllOutletUsers")
      .then((response) => {
        if (response.data.success) {
          setOutletUsersData(response.data.body);
        }
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }

  function makeid(length) {
    var result = "";
    var characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  const columns = [
    {
      dataField: "_id",
      text: "Id",
      hidden: true,
    },
    {
      dataField: "Outlet_Id.OutletName", text: "Outlet Name",
      classes: 'on mobile-td',
      sort: true,
      headerStyle: (column, colIndex) => {
        return { width: "160px" };
      }
    },
    {
      dataField: "name",
      text: "Name",
      classes: 'name mobile-td',
      sort: true,
      headerStyle: (column, colIndex) => {
        return { width: "160px" };
      }
    },
    {
      dataField: "emailID",
      text: "Email Address",
      classes: 'ea mobile-td',
      sort: true,
      headerStyle: (column, colIndex) => {
        return { width: "230px" };
      },
    },
    {
      dataField: "mobileNumber", text: "Mobile Number", classes: 'mobile mobile-td',
      headerStyle: (column, colIndex) => {
        return { width: "160px" };
      }
    },
    // { dataField: "PhoneNumber", text: "Phone Number" },
    // { dataField: "PANNumber", text: "PAN Number" },
    // {
    //   dataField: "Address",
    //   text: "Address",
    //   headerStyle: (column, colIndex) => {
    //     return { width: "200px", textAlign: "center" };
    //   },
    // },
    {
      text: "Actions",
      formatter: ActiveFormatter,
      headerStyle: (column, colIndex) => {
        return { width: "180px" };
      },
    },
  ];

  function onChange(e, controlName) {
    let eachVend = eachVendor;
    eachVend[controlName] = e.target.value;
    setEachVendor(eachVend);
  }
  function handleClose() {
    setShow(false);
    setIsNew(true);
    setEachVendor({});
  }

  function updatePassword(Id) {
    axios
      .put(process.env.REACT_APP_BASE_URL + `/vendors/update/${Id}`, {
        Password: "abc@123",
      })
      .then((response) => {
        if (response.data.success) {
          getAllOutletUsers();
        }
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }

  function onVendorUpdate(row) {
    setShow(true);
    setIsNew(false);
    setEachVendor(row);
  }
  function ActiveFormatter(cell, row) {
    return (
      <>
        <div className="action-btns" >
          {row.isActive && (
            <>
              <a onClick={() => onVendorUpdate(row)}>
                <FontAwesomeIcon key={makeid(10)} icon={faEdit} />
              </a>

              <a onClick={() => ActivateVendor(row._id, false)}>
                <FontAwesomeIcon key={makeid(10)} icon={faBan} />
              </a>

              <a onClick={() => DeleteVendor(row._id)}>
                <FontAwesomeIcon key={makeid(10)} icon={faTrash} />
              </a>
              <a onClick={() => updatePassword(row._id)}>
                <FontAwesomeIcon key={makeid(10)} icon={faKey} />
              </a>
            </>
          )}
          {!row.isActive && (
            <a onClick={() => ActivateVendor(row._id, true)}>
              <FontAwesomeIcon key={makeid(10)} icon={faCircleCheck} />
            </a>
          )}
        </div>
      </>
    );
  }

  function addVendor() {
    axios
      .post(process.env.REACT_APP_BASE_URL + "/vendors/create", eachVendor)
      .then((response) => {
        if (response.data.success) {
          getAllOutletUsers();
          handleClose();
        } else {
          alert(response.data.error);
          handleClose();
        }
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }

  function DeleteVendor(Id) {
    axios
      .put(process.env.REACT_APP_BASE_URL + `/vendors/update/${Id}`, {
        isDelete: true,
      })
      .then((response) => {
        if (response.data.success) {
          getAllOutletUsers();
        }
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }

  function ActivateVendor(Id, flag) {
    axios
      .put(process.env.REACT_APP_BASE_URL + `/vendors/update/${Id}`, {
        isActive: flag,
      })
      .then((response) => {
        if (response.data.success) {
          getAllOutletUsers();
        }
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }



  return (
    <>
      <Header />
      <div className="page-container">
        <div className="page-header">
          <h4 className="page-title">OutletUser List </h4>
          <Button
            className="float-right"
            onClick={handleNavigation}>
            <FontAwesomeIcon icon={faPlus} /> Add Outlet User
          </Button>
        </div>
        <div className="box-container menuItemsPage">
        <div className="table-responsive">
          <CustomTable data={OutletUsersData} columns={columns}></CustomTable>
        </div>
        </div>
      </div>
      <Footer />
      <div>
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>{isNew ? "Add " : "Update "} Vendor</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <Form>
              <Form.Group className="mb-3" controlId="formVendorName">
                <Form.Label>Vendor Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Station Name"
                  // defaultValue={eachVendor.VendorName}
                  onChange={(e) => onChange(e, "VendorName")}

                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="formBasicPassword">
                <Form.Label>Email Address</Form.Label>
                <Form.Control
                  type="email"
                  disabled={isNew ? false : true}
                  placeholder="Enter Email Address"
                  defaultValue={defaultValue}
                  onChange={(e) => onChange(e, "EmailID")}

                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="formBasicPassword">
                <Form.Label>Password</Form.Label>
                <Form.Control
                  type="password"
                  disabled={isNew ? false : true}
                  placeholder="Enter Password"
                  defaultValue={defaultValue}
                  onChange={(e) => onChange(e, "Password")}
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="formBasicPassword">
                <Form.Label>Mobile Number</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Mobile Number"
                  defaultValue={eachVendor.MobileNumber}
                  onChange={(e) => onChange(e, "MobileNumber")}
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="formBasicPassword">
                <Form.Label>Phone Number</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Phone Number"
                  defaultValue={eachVendor.PhoneNumber}
                  onChange={(e) => onChange(e, "PhoneNumber")}
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="formBasicPassword">
                <Form.Label>PAN Number</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter PAN Number"
                  defaultValue={eachVendor.PANNumber}
                  onChange={(e) => onChange(e, "PANNumber")}
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="formBasicPassword">
                <Form.Label>Address</Form.Label>
                <Form.Control
                  type="textarea"
                  placeholder="Enter Address"
                  defaultValue={eachVendor.Address}
                  onChange={(e) => onChange(e, "Address")}
                />
              </Form.Group>
            </Form>
          </Modal.Body>

          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button variant="primary" type="submit" onClick={addVendor}>
              {isNew ? "Save" : "Update"}
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
}
