import React from "react";
import { Routes, Route, Navigate, Link,redirect,useHistory } from "react-router-dom";
import Login from "./Components/Login/Login.component";
import PublicRoutes from "./Components/Routes/PublicRoutes";
import ProtectedRoutes from "./Components/Routes/ProtectedRoutes";
import Dashboard from "./Components/MainPage/Dashboard.component";
import Stations from "./Components/Stations/Stations.component";
import Vendors from "./Components/Vendor/Vendor.component";
import VendorAdd from "./Components/Vendor/VendorAdd.component";
import VendorEdit from "./Components/Vendor/VendorEdit.component";
import Outlets from "./Components/Outlets/Outlets.component";
import OutletAdd from "./Components/Outlets/OutletAdd.component";
import InnerContent from "./Components/MainPage/InnerContext.component";
import MenuItems from "./Components/MenuItems/MenuItems.component";
import AddMenuItem from "./Components/MenuItems/MenuAdd.component"
import Enquiry from "./Components/Enquiry/Enquiries.component";
import Users from "./Components/Users/Users.component";
import Refunds from "./Components/Refunds/Refunds.component";
import Orders from "./Components/Orders/Orders.component";
import MenuAdd from "./Components/MenuItems/MenuAdd.component";
import OutletUsers from "./Components/OutletUser/OutletUser.component";
import AddOutletUser from "./Components/OutletUser/OutletUserAdd.component";
import IRCTCPush from "./Components/IRCTCPush/IRCTCPush.component";
import Invoices from "./Components/Invoices/Invoices.component";
import InvoicesList from "./Components/InvoicesList/InvoicesList.component";

const MainRoutes = () => (
  <Routes>
    {/** Public Routes */}
    {/** Wrap all Route under PublicRoutes element */}
    <Route path="login" element={<PublicRoutes />}>
      <Route path="/login" element={<Login />} />
    </Route>

    {/** Protected Routes */}
    {/** Wrap all Route under ProtectedRoutes element */}
    <Route path="/" element={<ProtectedRoutes />}>
      <Route path="/" element={<InnerContent />}>
        <Route path="/" element={<Navigate replace to="dashboard" />} />
        <Route path="dashboard" element={<Dashboard />} />
        <Route path="stations" element={<Stations />} />
        <Route path="vendors" element={<Vendors />} />
        <Route path="vendor" element={<VendorAdd />} />
        <Route path="/vendorEdit/:id" element={<VendorEdit />} />       
        <Route path="Outlets" element={<Outlets />} />
        <Route path="outlet" element={<OutletAdd />} />
        <Route path="OutletUsers" element={<OutletUsers />} />
        <Route path="AddOutletUser" element={<AddOutletUser />} />
        <Route path="MenuItems" element={<MenuItems />} />
        <Route path="users" element={<Users />} />
        <Route path="Enquiries" element={<Enquiry />} />
        <Route path="Refunds" element={<Refunds />} />
        <Route path="Orders" element={<Orders/>} />
        <Route path="AddMenuItem" element={<MenuAdd/>} />
        <Route path="IRCTCPush" element={<IRCTCPush/>} />
        <Route path="Invoices" element={<Invoices/>} />
        <Route path="InvoicesList" element={<InvoicesList/>} />
      </Route>
    </Route>

    {/** Permission denied route */}
    {/* <Route path="/denied" element={<PermissionDenied />} /> */}
  </Routes>
);

export default MainRoutes;
