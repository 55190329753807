import React, { useEffect, useState } from "react";
import Header from "../header/Header";
import Footer from "../footer/Footer";
import axios from "axios";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  ArcElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar, Doughnut } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  ArcElement,
  Title,
  Tooltip,
  Legend
);

export default function Dashboard() {
  const [labelsnames, setlabels] = useState([]);
  const [labeldata, setlabelData] = useState([]);
  const [donutlabels, setdonutlabels] = useState([]);
  const [donutlabeldata, setdonutlabelData] = useState([]);
  const [Data, setData] = useState([]);
  const [dashboardInfo, setdashboardInfo] = useState({});
  //chart start
  // const labels=['January', 'February', 'March', 'April', 'May', 'June', 'July','August','September','October','November','December']
  const labels = labelsnames
  const data = {
    labels,
    datasets: [
      {
        label: "Orders",
        data: Data,
        backgroundColor: '#f98132',
      }
    ]
  }
  //chart end
  //dounght chart start
  const data1 = {
    labels: donutlabels,
    datasets: [
      {
        label: 'Sales',
        data: donutlabeldata,
        backgroundColor: [          
          '#90A4AE','#f98132','#ADFF2F',
          '#1cc88a', '#0000FF', '#FFFF00' 
          ,'#263238', '#FF0000','#FF00FF'
        ],
        // borderColor: [
        //   'rgba(255, 99, 132, 1)',
        //   'rgba(54, 162, 235, 1)',
        //   'rgba(255, 206, 86, 1)',
        //   'rgba(75, 192, 192, 1)',
        //   'rgba(153, 102, 255, 1)',
        //   'rgba(255, 159, 64, 1)',
        // ],
        borderWidth: 1,

      },
    ],
  };
  //dounght chart end
  useEffect(() => {
    getDashboardDetails();
    getMonthlywiseOrdersDetails();
    getSalesReportdDetails();
  }, []);
  function getDashboardDetails() {
    axios
      .get(process.env.REACT_APP_BASE_URL + "/dashboard/getDashboardData")
      .then((response) => {
        console.log(response);
        if (response.data.success) {
          let dashboardInfo = {
            totalVendorscount: response.data.body.totalVendorscount,
            stationscount: response.data.body.stationscount,
            menuItemsCount: response.data.body.menuItemsCount,
            totalOrders: response.data.body.totalOrders,
            totalOutlets: response.data.body.totalOutlets,
            pendingEnquiries: response.data.body.pendingEnquiries
          };
          setdashboardInfo(dashboardInfo);
        }
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }
  function getMonthlywiseOrdersDetails() {
    axios
      .get(process.env.REACT_APP_BASE_URL + "/dashboard/getMonthlywiseOrdersDetails")
      .then((response) => {

        if (response.data.success) {
          var lablevalues = [];
          var datavalues = [];
          response.data.body.forEach((element) => {

            lablevalues.push(element._id.month+'-'+element._id.year);
            setlabelData(element._id.year)
            datavalues.push(element.count);
          });
          setlabels(lablevalues);
          setData(datavalues);
        }
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }
  function getSalesReportdDetails() {
    axios
      .get(process.env.REACT_APP_BASE_URL + "/dashboard/getSaleReport")
      .then((response) => {
        console.log(response);
        if (response.data.success) {
          var donutlablevalues = [];
          var dountdatavalues = [];
          response.data.body.forEach((element) => {

            donutlablevalues.push(element.status.replace('ORDER_', ''));
            dountdatavalues.push(element.count);
          });
          setdonutlabels(donutlablevalues);
          setdonutlabelData(dountdatavalues);
        }
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }

  return (
    <>
      <Header />
      <div className="page-container">
        <div className="page-header">
          <h4 className="page-title">Dashboard</h4>
        </div>
        <div className="box-container">
          <div className="row">
           
            <div className="col-lg-2 col-sm-4">
              <div className="db-box-item">
                <div className="label">Total Stations</div>
                <div className="number">{dashboardInfo.stationscount}</div>
              </div>
            </div>
            <div className="col-lg-2 col-sm-4">
              <div className="db-box-item">
                <div className="label">Total Vendors</div>
                <div className="number">{dashboardInfo.totalVendorscount}</div>
              </div>
            </div>
            <div className="col-lg-2 col-sm-4">
              <div className="db-box-item">
                <div className="label">Total Menu Items</div>
                <div className="number">{dashboardInfo.menuItemsCount}</div>
              </div>
            </div>
            <div className="col-lg-2 col-sm-4">
              <div className="db-box-item">
                <div className="label">Total Orders</div>
                <div className="number">{dashboardInfo.totalOrders}</div>
              </div>
            </div>
            <div className="col-lg-2 col-sm-4">
              <div className="db-box-item">
                <div className="label">Total Outlets</div>
                <div className="number">{dashboardInfo.totalOutlets}</div>
              </div>
            </div>
            <div className="col-lg-2 col-sm-4">
              <div className="db-box-item">
                <div className="label">Pending Enquiries</div>
                <div className="number">{dashboardInfo.pendingEnquiries}</div>
              </div>
            </div>
          </div>

          <div className="row mt-4">
            <div className="col-md-8">
              <div className="card td-shadow mh-250">
                <div className="card-header">Monthly Orders Overview</div>
                <div className="card-body">
                  {/* <img
          src={require("../../Assets/Images/c1.png")}
          alt="Vendor"
        ></img> */}
                  <Bar data={data} />
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="card td-shadow mh-250">
                <div className="card-header">Sale Reporting</div>
                <div className="card-body text-center">
                  {/* <img
          src={require("../../Assets/Images/c2.png")}
          alt="Vendor"
        ></img> */}
                  <Doughnut data={data1} />
                </div>
              </div>
            </div>
          </div>

        </div>


      </div>


      <Footer />
    </>
  );
}
