import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { navigationItems } from "../../Config/Navigation";
import SideNav, { Toggle, Nav, NavItem, NavIcon, NavText } from '@trendmicro/react-sidenav';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faDashboard,
  faTrain,
  faKitchenSet,
  faMoneyBillWave,
  faBolt,
  faUsers,
  faShop,
  faUserAstronaut,
  faPizzaSlice,
  faSignOut,
  faDatabase
} from "@fortawesome/free-solid-svg-icons";
// Be sure to include styles at some point, probably during your bootstraping
import '@trendmicro/react-sidenav/dist/react-sidenav.css';

export default function SideBar(props) {
  const location = useLocation();
  const navigation = useNavigate();
  const [expanded, setExpanded] = useState(false);
  const [selected, setSelected] = useState("");

  useEffect(() => {
    //setSelected("dashboard");
    //navigation("/dashboard");
  }, [])

  const logout = () => {
    localStorage.removeItem("user");
    navigation("/login");
  };

  const onSelect = (selected) => {
    
    if(selected == "/login")
    {
      localStorage.removeItem("user");
    }
    setSelected(selected);
    navigation(selected);
  };
  const onToggle = (expanded) => {
    console.log("expanded",expanded);
    props.setAction(expanded);
    setExpanded(expanded);
  };

  return (
    <>
      <SideNav
        onSelect={onSelect} onToggle={onToggle} 
      >
        <SideNav.Toggle />
        <SideNav.Nav >
          {navigationItems.sidebar.map((item) => (
            <NavItem eventKey={item.to}>
              <NavIcon>
                <FontAwesomeIcon
                  icon={
                    item.text === "dashboard"
                      ? faDashboard
                      : item.text === "Stations"
                        ? faTrain
                        : item.text === "Vendor"
                          ? faUserAstronaut
                          : item.text === "Outlets"
                            ? faShop
                            : item.text === "Enquiries"
                              ? faBolt
                              : item.text === "Refunds"
                                ? faMoneyBillWave
                                : item.text === "Orders"
                                  ? faKitchenSet
                                  : item.text === "users"
                                    ? faUsers
                                    : item.text === "MenuItems"
                                    ? faPizzaSlice
                                    : item.text === "IRCTCPush"
                                    ? faDatabase
                                    : item.text === "Invoices"
                                    ? faMoneyBillWave
                                    : item.text === "InvoicesList"
                                    ? faMoneyBillWave
                                    : item.text === "Logout"
                                    ? faSignOut
                                    : null
                  }
                  style={{ paddingRight: "2%" }}
                />
              </NavIcon>
              <NavText>
                {item.name}
              </NavText>
            </NavItem>
          ))}


        </SideNav.Nav>
      </SideNav>
    </>
  );
}
