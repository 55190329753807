import React, { useEffect, useState } from "react";
import axios from "axios";
import { Form, Button, Container } from 'react-bootstrap';
import { Routes, Route, Navigate, Link, useNavigate } from "react-router-dom";
import TimePicker from 'react-bootstrap-time-picker';
import './MenuItems.css';
import Header from "../header/Header";
import Footer from "../footer/Footer";


const MenuAdd = () => {

  const [formData, setFormData] = useState({});
  const [openingTime, setOpeningTime] = useState(null);
  const [closingTime, setClosingTime] = useState(null);
  const [foodTypes, setFoodTypes] = useState([]);
  const [cuisineTypes, setCuisineTypes] = useState([]);
  const [vendors, setVendors] = useState([]);
  const [outlets, setOutlets] = useState([]);
  const [selectedLogo, setSelectedLogo] = useState(null);
  const [isChecked, setIsChecked] = useState(false);
  const [isActive, setIsActive] = useState(false);

  const [errors, setErrors] = useState({
    Cat_Type: '',
    Item_Name: '',
    Selling_Price: '',
    Base_Price: '',
    Description: '',
    Allergen_Info: '',
    Calories: '',
    //isActive:false,
    Cuisine: '',
    Food_Type: '',
    Closing_Time: '',
    Opening_Time: '',
    Logo_Id: '',
    //isGST: false
  });

  const logoSelectedHandler = (event) => {
    var selectedFile = event.target.files[0];
    var idxDot = selectedFile.name.lastIndexOf(".") + 1;
    var extFile = selectedFile.name.substr(idxDot, selectedFile.name.length).toLowerCase();
    if (extFile == "jpg" || extFile == "jpeg" || extFile == "png") {
      setSelectedLogo(event.target.files[0]);
    } else {
      alert("Only jpg/jpeg and png files are allowed!");
      event.target.value = null;
    }

  }

  const selectedOpeningTime = (event) => {
    var date = new Date(0);
    date.setSeconds(event); // specify value for SECONDS here
    var timeString = date.toISOString().substr(11, 8);
    console.log(timeString)
    setOpeningTime(timeString);
    setFormData(prevState => ({
      ...prevState,
      ['Opening_Time']: timeString
    }));
    console.log(formData);
  }

  useEffect(() => {
    console.log("========getfoodtypes=================")
    setFoodTypes([]);
    fetchFoodTypes();
    getAllVendors();
    //getOutletsByVendorId();
  }, [])

  const selectedClosingTime = (event) => {
    var date = new Date(0);
    date.setSeconds(event); // specify value for SECONDS here
    var timeString = date.toISOString().substr(11, 8);
    console.log(timeString)
    setClosingTime(timeString);
    setFormData(prevState => ({
      ...prevState,
      ['Closing_Time']: timeString
    }));
  }

  const handleIsAvailable = (e) => {
    var isActive = false;
    if (e.target.value == "on") {
      isActive = true;
    }
    setFormData(prevState => ({
      ...prevState,
      ["isActive"]: isActive
    }));
  };

  const handleChange = (e) => {
    console.log(formData);
    const { name, value } = e.target;
    console.log("name", name, "value:", value);

    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
    if (name === 'VendorId') {
      getOutletsByVendorId(value);
    }
    console.log("Update form data:", formData);
  };

  const checkValidation = () => {
    const newErrors = {};
    const name_regex = /^[A-Za-z0-9\s]+$/;
    if (!formData.Cat_Type) {
      newErrors.Cat_Type = 'Please select category';
    }

    console.log("validation block")
    if (!formData.Item_Name) {
      newErrors.Item_Name = 'Please enter item name';
    }
    if (!formData.Selling_Price) {
      newErrors.Selling_Price = "Please enter selling price";
    }
    if (!formData.Base_Price) {
      newErrors.Base_Price = 'Please enter base price';
    }
    if (!formData.Description) {
      newErrors.Description = 'Please enter the description';
    }
    if (!formData.Calories) {
      newErrors.Calories = 'Please enter calories';
    }
    if (!formData.Allergen_Info) {
      newErrors.Allergen_Info = 'Please enter allergey info';
    }
    // if (!formData.Opening_Time) {
    //   newErrors.Opening_Time ='Please select opening time';
    // }
    // if (!formData.Closing_Time) {
    //   newErrors.Closing_Time ='Please select closing time';
    // }

    if (!formData.Cuisine) {
      newErrors.Cuisine = 'Please select cuisine type';
    }

    if (!formData.Food_Type) {
      newErrors.Food_Type = 'Please select food type';
    }



    setErrors(newErrors);

    // If there are no errors, you can proceed with form submission
    if (Object.keys(newErrors).length === 0) {
      //setIsLoading(false);
      // Perform your form submission logic here
      //console.log('Form submitted successfully');

      console.log("Form data", formData)

      return true;
    } else {
      return false;
    }
  };


  const navigate = useNavigate();

  const handleNavigation = () => {
    navigate('/MenuItems');
  };

  const handleGSTChange = (e) => {
    console.log("FORM DATA:", !isChecked)
    const { name, value } = e.target;
    console.log("name:", name, " value:", value);
    setIsChecked(!isChecked);
    setFormData(prevState => ({
      ...prevState,
      [name]: !isChecked
    }));
    console.log("Updated form data:", formData)
  };

  const handleActiveChange = (e) => {
    console.log("FORM DATA:", !isActive)
    const { name, value } = e.target;
    console.log("name:", name, " value:", value);
    setIsActive(!isActive);
    setFormData(prevState => ({
      ...prevState,
      [name]: !isActive
    }));
    console.log("Updated form data:", formData)
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    if (checkValidation()) {
      //setIsLoading(false);
      // Perform your form submission logic here
      console.log('Form submitted successfully');
      let form_Data = new FormData();
      form_Data.append('menuInfo', JSON.stringify(formData));
      if (selectedLogo !== null) {
        form_Data.append('file', selectedLogo);
      }
      console.log("api request form data=", formData);
      //return false;
      axios
        .post(process.env.REACT_APP_BASE_URL + "/MenuItems/createMenuItem", form_Data)
        .then((response) => {
          if (response.data.success) {
            console.log("menu create api response", response.data)
            handleNavigation();
          } else {
            alert(response.data.msg);
            console.log("Error respone", response.data);
          }
        })
        .catch((error) => {
          console.error("There was an error!", error);
        });


    }

  }

  function fetchFoodTypes() {
    console.log("========getfoodtypes==API===============")
    axios
      .get(process.env.REACT_APP_BASE_URL + "/MenuItems/getFoodTypesCuisineTypes")
      .then((response) => {
        if (response.data.success) {
          setFoodTypes(response.data.body.food_types);
          setCuisineTypes(response.data.body.cuisine_types);
          console.log("========getfoodtypes==API==Response=============")
          console.log('Food Types', foodTypes);
        } else {
          alert(response.data.error);

        }
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }

  function getAllVendors() {
    axios
      .get(process.env.REACT_APP_BASE_URL + "/vendors/getAllVendors")
      .then((response) => {
        if (response.data.success) {
          setVendors(response.data.body);
        }
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }

  function getOutletsByVendorId(vendorId) {
    //console.log("ENV URL", process.env.REACT_APP_BASE_URL)
    //const user = JSON.parse(localStorage.getItem("user"));
    axios
      .get(process.env.REACT_APP_BASE_URL + `/Outlets/getOutletsByVendorId/${vendorId}`)
      .then((response) => {
        if (response.data.success) {
          setOutlets(response.data.body);
        }
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }

  return (
    <>

      <Header />
      <div className="page-container">
        <div className="page-header menu-items-header">
          <h4 className="page-title">Add Menu Item</h4>
        </div>
        <div className="box-container">
          <Form onSubmit={handleSubmit} className="add-menuitems-form">
            <Form.Group className="w-50" controlId="VendorId">
              <Form.Label>Vendor <span className="error">*</span></Form.Label>
              <Form.Select
                class="form-select form-select-lg mb-3"
                aria-label=".form-select-lg example"
                onChange={handleChange}
                name="VendorId"

              >
                <option value="">Select Vendor</option>
                {vendors.map((i) => (
                  <option value={i._id} key={i._id}>
                    {i.VendorName}
                  </option>
                ))}

              </Form.Select>
              <span className="error">{errors.VendorId}</span>
              <Form.Control.Feedback type="invalid">
                Please select a valid vendor.
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="w-50" controlId="Outlet_Id">
              <Form.Label>Outlets <span className="error">*</span></Form.Label>
              <Form.Select
                class="form-select form-select-lg mb-3"
                aria-label=".form-select-lg example"
                onChange={handleChange}
                name="Outlet_Id"

              >
                <option value="">Select Outlet</option>
                {outlets ? outlets.map((i) => (
                  <option value={i._id} key={i._id}>
                    {i.OutletName}
                  </option>
                )) : null}

              </Form.Select>
              <Form.Control.Feedback type="invalid">
                Please select a valid vendor.
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="w-50" controlId="Cat_Type">
              <Form.Label>Category <span className="error">*</span></Form.Label>
              <Form.Select
                class="form-select form-select-lg mb-3"
                aria-label=".form-select-lg example"
                onChange={handleChange}
                name="Cat_Type"
              >
                <option value="">Select Category Type</option>
                <option value="veg">Vegetarian</option>
                <option value="non-veg">Non Vegetarian</option>

              </Form.Select>
              <span className="error">{errors.Cat_Type}</span>
              <Form.Control.Feedback type="invalid">
                Please select a valid category.
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="w-50" controlId="Item_Name">
              <Form.Label>Item Name <span className="error">*</span></Form.Label>
              <Form.Control
                type="text"
                name="Item_Name"
                onChange={handleChange}
                placeholder="Enter Item Name"

              />
              <span className="error">{errors.Item_Name}</span>
              <Form.Control.Feedback type="invalid">
                Please provide a valid outlet name.
              </Form.Control.Feedback>

            </Form.Group>
            <Form.Group className="w-50" controlId="Selling_Price">
              <Form.Label>Selling Price <span className="error">*</span></Form.Label>
              <Form.Control
                type="number"
                name="Selling_Price"
                onChange={handleChange}
                placeholder="Enter selling price"

              />
              <span className="error">{errors.Selling_Price}</span>
              <Form.Control.Feedback type="invalid">
                Please provide a valid Item price.
              </Form.Control.Feedback>

            </Form.Group>


            <Form.Group className="w-50" controlId="Base_Price">
              <Form.Label>Base Price <span className="error">*</span></Form.Label>
              <Form.Control
                type="text"
                name="Base_Price"
                onChange={handleChange}
                placeholder="Enter base price"

              />
              <span className="error">{errors.Base_Price}</span>
              <Form.Control.Feedback type="invalid">
                Please provide a valid Item price.
              </Form.Control.Feedback>

            </Form.Group>

            <Form.Group className="w-50" controlId="Cuisine">
              <Form.Label>Cuisine Type <span className="error">*</span></Form.Label>
              <Form.Select
                class="form-select form-select-lg mb-3"
                aria-label=".form-select-lg example"
                onChange={handleChange}
                name="Cuisine"

              >
                <option value="">Select Cuisine Type</option>
                {cuisineTypes.map((i) => (
                  <option value={i._id} key={i._id}>
                    {i.CuisineType}
                  </option>
                ))}

              </Form.Select>
              <span className="error">{errors.Cuisine}</span>
              <Form.Control.Feedback type="invalid">
                Please select a valid station.
              </Form.Control.Feedback>

            </Form.Group>

            <Form.Group className="w-50" controlId="Food_Type">
              <Form.Label>Food Type <span className="error">*</span></Form.Label>
              <Form.Select
                class="form-select form-select-lg mb-3"
                aria-label=".form-select-lg example"
                onChange={handleChange}
                name="Food_Type"

              >
                <option value="">Select Food Type</option>
                {foodTypes.map((i) => (
                  <option value={i._id} key={i._id}>
                    {i.FoodType}
                  </option>
                ))}

              </Form.Select>
              <span className="error">{errors.Food_Type}</span>
              <Form.Control.Feedback type="invalid">
                Please select a valid station.
              </Form.Control.Feedback>

            </Form.Group>

            <Form.Group controlId="Description">
              <Form.Label>Description</Form.Label>
              <Form.Control className="w-50" name="Description" as="textarea" rows={3}
                value={formData.Description}
                onChange={handleChange}

              />
              <span className="error">{errors.Description}</span>
              <Form.Control.Feedback type="invalid">
                Please provide a valid Description.
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="w-50" controlId="Calories">
              <Form.Label>Calories <span className="error">*</span></Form.Label>
              <Form.Control
                type="text"
                name="Calories"
                onChange={handleChange}
                placeholder="Enter Calories"

              />
              <span className="error">{errors.Calories}</span>
              <Form.Control.Feedback type="invalid">
                Please provide a valid calories.
              </Form.Control.Feedback>

            </Form.Group>

            <Form.Group className="w-50" controlId="Allergen_Info">
              <Form.Label>Allergey Info <span className="error">*</span></Form.Label>
              <Form.Control
                type="text"
                name="Allergen_Info"
                onChange={handleChange}
                placeholder="Enter allergey info"

              />
              <span className="error">{errors.Allergen_Info}</span>
              <Form.Control.Feedback type="invalid">
                Please provide a valid allergey info.
              </Form.Control.Feedback>

            </Form.Group>


            <Form.Group className="w-50" controlId="Opening_Time">
              <Form.Label>Opening Time <span className="error">*</span></Form.Label>
              <TimePicker start="00:00" end="23:59" step={30} value={openingTime} onChange={selectedOpeningTime} initialValue="00:00"
                name="Opening_Time" />
              <span className="error">{errors.Opening_Time}</span>
              <Form.Control.Feedback type="invalid">
                Please provide a valid opening time.
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="w-50" controlId="Closing_Time">
              <Form.Label>Closing Time <span className="error">*</span></Form.Label>
              <TimePicker start="00:00" end="23:59" step={30} value={closingTime} onChange={selectedClosingTime} initialValue="00:00"
                name="Closing_Time" />
              <span className="error">{errors.Closing_Time}</span>
              <Form.Control.Feedback type="invalid">
                Please provide a valid closing time.
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="w-50" controlId="Logo">
              <Form.Label>Logo <span className="error">*</span></Form.Label>
              <Form.Control type="file" accept=".png, .jpg, .jpeg" onChange={logoSelectedHandler} />
            </Form.Group>
          

            {/* <Form.Group className="row w-30" controlId="isGST">
              <Form.Label className="col-md-2">GST <span className="error">*</span> </Form.Label>
              <Form.Switch
              className="col-md-10"            
              name="isGST"
              onChange={handleChange}
                           
              />             
              
            </Form.Group><br /> */}

            {/* <Form.Group className="row w-30" controlId="isActive">
              <Form.Label className="col-md-2">Menut Item on/off <span className="error">*</span> </Form.Label>
              <Form.Switch
              className="col-md-10"            
              name="isActive"
              onChange={handleChange}
                           
              /> 

            </Form.Group> */}

            <Form.Group className="mb-3" controlId="isActive">
              <Form.Check
                type="switch"
                name="isActive"
                label="Item on/off"
                checked={isActive}
                onChange={handleActiveChange}
              />
            </Form.Group>
            {/* <p>Switch is {isActive ? 'On' : 'Off'}</p> */}

            <Form.Group className="mb-3" controlId="isGST">
              <Form.Check
                type="switch"
                name="isGST"
                label="GST on/off"
                checked={isChecked}
                onChange={handleGSTChange}
              />
            </Form.Group>
            {/* <p>Switch is {isChecked ? 'On' : 'Off'}</p> */}

            <Form.Group className="w-50" controlId="formSubmit">
              <Button type="submit" className="xs-btn-block">Submit</Button>
            </Form.Group>

          </Form>
        </div>
      </div>
      <Footer />
    </>

  );
}

export default MenuAdd;