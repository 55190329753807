import React, { useEffect, useState } from "react";
//import CustomTable from "../TableComponent/CustomTable.component";
import axios from "axios";
import TimePicker from 'react-bootstrap-time-picker';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Datetime from 'react-datetime';
import "react-datetime/css/react-datetime.css";
import 'react-phone-number-input/style.css';
import CheckboxGroup from 'react-checkbox-group';
import Header from "../header/Header";
import Footer from "../footer/Footer";
/*
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import "./Vendor.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTrash,
  faEdit,
  faCircleCheck,
  faBan,
  faKey,
} from "@fortawesome/free-solid-svg-icons";*/

import "./Vendor.css";
import { Form, Button} from 'react-bootstrap';
import {useNavigate, useParams } from "react-router-dom";
import Swal from 'sweetalert2';


const VendorEdit = () => {
  const { id } = useParams();
  console.log("type value", id);
  const [formData, setFormData] = useState({
    Id:'',
    Station_Id: '',
    VendorName: '',  
    outletName: '',
    PANNumber: '',
    MobileNumber: '',
    vendorEmail: '',
    GSTNumber:'',
    Address: '',
    AccountNo: '',
    AccountName: '',
    IFSCCode: '',
    AccountType: '',
    OnlineTransactionNo: '',
   
  });
  const [loadStations, setLoadStations] = useState([]);
  const [isDuplicate, setIsDuplicate] = useState(false);
  const [stationIdErr, setStationIdErr] = useState("")
  const [vendorNameErr, setvendorNameErr] = useState("")
  const [vendorPasswordErr, setVendorPasswordErr] = useState("")

  const [vendorConfirmPasswordErr, setconfirmPasswordErr] = useState("")
  const [outletNameErr, setoutletNameErr] = useState("")
  const [panErr, setPanErr] = useState("")
  const [gstErr, setGSTErr] = useState("")
  const [mobileNbrErr, setMobileNbrErr] = useState("")
  const [vendorEmailErr, setVendorEmailErr] = useState("")
  const [vendorAddresErr, setVendorAddresEr] = useState("")
  const [vendorData, setVendorData] = useState({})
  const [acnoErr, setacnoErr] = useState("")
  const [acnameErr, setacnameErr] = useState("")
  const [ifscErr, setifscErr] = useState("")
  const [actypeErr, setactypeErr] = useState("")
  const [onmobiletypeErr, setonmobiletypeErr] = useState("")
  
  // const [validated, setValidated] = useState(false);
  
  const checkValidation = (name, value) => {
    const indianMobileNumberRegex = /^[6-9]\d{9}$/;
    const strongPasswordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%^*?&])[A-Za-z\d@$!%^*?&]{8,15}$/;
    const name_regex = /^[A-Za-z0-9\s]+$/;
    const panRegex = /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/;
    const emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    //const gstRegex = /'^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]1}[1-9A-Z]{1}Z[0-9A-Z]{1}$'/;

    switch (name) {

      case 'Station_Id':
        setStationIdErr(value === '0' || value === '' ? 'Please select the station' : '');
        return !(value === '0' || value === '');
  
      case 'VendorName':
        setvendorNameErr(!(name_regex.test(value)) ? 'Please enter valid vendor name' : '');
        return name_regex.test(value) && value !== '';
  
      case 'outletName':
        setoutletNameErr(!name_regex.test(value) ? 'Please enter valid outlet name' : '');
        return name_regex.test(value) && value !== '';
  
      case 'MobileNumber':
        setMobileNbrErr(!(indianMobileNumberRegex.test(value)) && value !== '' ? 'Enter valid mobile number' : '');
        return indianMobileNumberRegex.test(value) || value === '';
  
      case 'vendorEmail':
        setVendorEmailErr(!(emailRex.test(value)) ? 'Invalid email address' : '');
        return emailRex.test(value) && value !== ''; 
     
  
      case 'PANNumber':
        setPanErr(!panRegex.test(value) ? 'Please enter valid PAN number' : '');
        return panRegex.test(value) && value !== '';
        case 'GSTNumber':
        setGSTErr(value === '0' || value === '' ? 'Please enter valid GST number' : '');
        return !(value === '0' || value === '');
  
      case 'Address':
        setVendorAddresEr(value === '' ? 'Please enter address' : '');
        return value !== '';
        case 'AccountNo':
          setacnoErr(!(name_regex.test(value)) ? 'Please enter valid account number' : '');
          return name_regex.test(value) && value !== '';
         
          case 'AccountName':
            setacnameErr(!(name_regex.test(value)) ? 'Please enter valid account name' : '');
            return name_regex.test(value) && value !== '';
            case 'IFSCCode':
              setifscErr(!(name_regex.test(value)) ? 'Please enter valid IFSC code' : '');
              return name_regex.test(value) && value !== '';
              case 'AccountType':
                setactypeErr(!(name_regex.test(value)) ? 'Please enter valid account type' : '');
              return name_regex.test(value) && value !== '';
              case 'OnlineTransactionNo':
                setonmobiletypeErr(!(name_regex.test(value)) ? 'Please enter valid PhonePe/Gpay/Paytm number' : '');
                return name_regex.test(value) && value !== '';
              
                default:
        return true;
    }
  };
  

  const navigate = useNavigate();

  const handleNavigation = () => {
    navigate('/vendors');
  };

  function updateFormData(name,value)
  {
    setFormData((prevData) => ({
      ...prevData,

      [name]: value,
    }));
  }

  const handleChange = (e) => {
    
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,

      [name]: value,
    }));

    if (name === 'vendorEmail') {
      setIsDuplicate(false);
    }
    if (name === 'Station_Id') {
      // setErrors((prevErrors) => ({
      //   ...prevErrors,
      //   station: '',
      // }));
    }
  
    checkValidation(name,value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    /*const form = e.currentTarget;
    if (form.checkValidity()) {
      console.log('Form submitted successfully:', formData);
      // You can perform your form submission logic here
    } else {
      e.stopPropagation();
    }

    setValidated(true);*/
    // If there are no errors, you can proceed with form submission
    let isValid = true;
    

   

    for (const [name, value] of Object.entries(formData)) {
      if (!checkValidation(name, value)) {
        isValid = false; // Set isValid to false if any field fails validation
         // Exit the loop since the form is already considered invalid
      }

    }
    console.log("isvalid-vend", isValid, formData)

    if (isValid) {
   
      axios
        .post(process.env.REACT_APP_BASE_URL + "/vendors/updateVendor", formData)
        .then((response) => {
          if (response.data.success) {
            console.log("Vendor create api response", response.data)
            Swal.fire({
              position: "center",
              icon: "success",
              title: "Vendor details updated successfully.",
              showConfirmButton: false,
              timer: 3500
            });
            handleNavigation();
          } else {
            //alert(JSON.stringify(response.data.error));
            console.log("Error response", response.data)
  
            if (response.data.msg === "duplicate") {
              setIsDuplicate(true);
              Swal.fire({
                position: "center",
                icon: "error",
                title: "Email already exists.",
                showConfirmButton: false,
                timer: 4000
              });
            }
          }
        })
        .catch((error) => {
          console.error("There was an error!", error);
        });


    // for (const [name, value] of Object.entries(formData)) {
    //   if (checkValidation(name, value)){
    //     axios
    //     .post(process.env.REACT_APP_BASE_URL + "/vendors/create", formData)
    //     .then((response) => {
    //       if (response.data.success) {
    //         console.log("Vendor create api response", response.data)
    //         handleNavigation();
    //       } else {
    //         //alert(JSON.stringify(response.data.error));
    //         console.log("Error respone", response.data)

    //         if (response.data.msg == 'duplicate') {
    //           setIsDuplicate(true);
    //         }
    //       }
    //     })
    //     .catch((error) => {
    //       console.error("There was an error!", error);
    //     });

    //   }
      
    // }
  
    

  };

  
   

}

  useEffect(() => {
    //console.log('triggered');
    getVendorById(id);
    getAllStations();
  }, []);

  function getVendorById(id) {
    axios
      .get(process.env.REACT_APP_BASE_URL + `/vendors/getVendorById/${id}`)
      .then((response) => {
        if (response.data.success) {
          setVendorData(response.data.body);
          updateFormData("Id",response.data.body._id);
          updateFormData("vendorEmail",response.data.body.user_Id.emailID);
          updateFormData("MobileNumber",response.data.body.MobileNumber);
          updateFormData("PANNumber",response.data.body.PANNumber);
          updateFormData("Address",response.data.body.Address);
          updateFormData("outletName",response.data.body.outletName); 
          updateFormData("VendorName",response.data.body.VendorName);
          updateFormData("GSTNumber",response.data.body.GSTNumber);
          updateFormData("Station_Id",response.data.body.Station_Id);
          updateFormData("AccountNo",response.data.body.AccountNo);
          updateFormData("AccountName",response.data.body.AccountName);
          updateFormData("IFSCCode",response.data.body.IFSCCode);
          updateFormData("AccountType",response.data.body.AccountType);
          updateFormData("OnlineTransactionNo",response.data.body.OnlineTransactionNo);
          updateFormData("FSSAI_NO",response.data.body.FSSAI_NO);
          updateFormData("FSSAI_Valid_Upto",response.data.body.FSSAI_Valid_Upto);
          updateFormData("Closing_Period_From",response.data.body.Closing_Period_From);
          updateFormData("Closing_Period_To",response.data.body.Closing_Period_To);
          updateFormData("WeeklyClosed",response.data.body.WeeklyClosed);

          //setLoadStations(response.data.body);
        }
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }

  function getAllStations() {
    axios
      .get(process.env.REACT_APP_BASE_URL + "/stations/getAllStations")
      .then((response) => {
        if (response.data.success) {
          setLoadStations(response.data.body);
        }
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }



  return (
    <>
      <Header />
      <div className="page-container">
        <div className="page-header">
          <h4 className="page-title">Update Vendor</h4>

        </div>
        <div className="text-left">
          <span className="error">{isDuplicate ? 'Email ID already exists to some other user.' : ''}</span>
        </div>
        <div className="box-container">
          <Form onSubmit={handleSubmit} className="add-outlet-user-form">
         
            <div className="row">
            <Form.Group controlId="station" className="w-50">
              <Form.Label>Station <span className="error">*</span></Form.Label>
              <Form.Select name="Station_Id" aria-label="Default select example" onChange={handleChange} >
                <option value="0"> --- Select Station ---</option>
                {loadStations? loadStations.map((i) => (
                  <option value={i._id} key={i._id} selected={i._id == formData.Station_Id}>
                    {i.StationName} - ({i.StationCode})
                  </option>
                )):null}
              </Form.Select>
              <span className="error">{stationIdErr}</span>
              <Form.Control.Feedback type="invalid">
                Please select the vendor station.
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="VendorName" className="w-50">
              <Form.Label>Vendor Name <span className="error">*</span></Form.Label>
              <Form.Control
                type="text"
                name="VendorName"
                value={formData.VendorName}
                onChange={handleChange}
              />
              <span className="error">{vendorNameErr}</span>
              <Form.Control.Feedback type="invalid">
                Please provide a valid name.
              </Form.Control.Feedback>
            </Form.Group>
            </div>
            <div className="row">

            <Form.Group controlId="outletname" className="w-50">
              <Form.Label>Outlet Name <span className="error">*</span></Form.Label>
              <Form.Control
                type="text"
                name="outletName"
                value={formData.outletName}
                onChange={handleChange}
              />
              <span className="error">{outletNameErr}</span>
              <Form.Control.Feedback type="invalid">
                Please provide a valid outlet name.
              </Form.Control.Feedback>
            </Form.Group>


            <Form.Group controlId="email" className="w-50">
              <Form.Label>Email <span className="error">*</span></Form.Label>
              <Form.Control
                type="email"
                name="vendorEmail"
                value={formData.vendorEmail}
                onChange={handleChange}
              />
              <span className="error">{vendorEmailErr}</span>
              <Form.Control.Feedback type="invalid">
                Please provide a valid email.
              </Form.Control.Feedback>
            </Form.Group>
            </div>
            <div className="row">
            <Form.Group controlId="MobileNumber" className="w-50">
              <Form.Label>Mobile <span className="error">*</span></Form.Label>
              <Form.Control
                type="number"
                name="MobileNumber"
                value={formData.MobileNumber}
                onChange={handleChange}
              />
              <span className="error">{mobileNbrErr}</span>
              <Form.Control.Feedback type="invalid">
                Please provide a valid mobile.
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId="PANNumber" className="w-50">
              <Form.Label>PAN Number <span className="error">*</span></Form.Label>
              <Form.Control
                type="text"
                name="PANNumber"
                value={formData.PANNumber}
                onChange={handleChange}
                onBlur={checkValidation}
              />
              <span className="error">{panErr}</span>
              <Form.Control.Feedback type="invalid">
                Please provide a valid PAN number.
              </Form.Control.Feedback>
            </Form.Group>
            </div>
            <div className="row">
            <Form.Group controlId="GSTNumber" className="w-50">
              <Form.Label>GST Number <span className="error">*</span></Form.Label>
              <Form.Control
                type="text"
                name="GSTNumber"
                value={formData.GSTNumber}
                onChange={handleChange}
                onBlur={checkValidation}
              />
              <span className="error">{gstErr}</span>
              <Form.Control.Feedback type="invalid">
                Please provide a valid GST number.
              </Form.Control.Feedback>
            </Form.Group>

           
            <Form.Group controlId="AccountNo" className="w-50">
              <Form.Label>Account Number <span className="error">*</span></Form.Label>
              <Form.Control
                type="number"
                name="AccountNo"
                value={formData.AccountNo}
                onChange={handleChange}
                onBlur={checkValidation}
              />
              <span className="error">{acnoErr}</span>
              <Form.Control.Feedback type="invalid">
                Please provide a valid account number.
              </Form.Control.Feedback>
            </Form.Group>
            </div>
            <div className="row">
            <Form.Group controlId="AccountName" className="w-50">
              <Form.Label>Account Name <span className="error">*</span></Form.Label>
              <Form.Control
                type="text"
                name="AccountName"
                value={formData.AccountName}
                onChange={handleChange}
                onBlur={checkValidation}
              />
              <span className="error">{acnameErr}</span>
              <Form.Control.Feedback type="invalid">
                Please provide a valid account name.
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="IFSCCode" className="w-50">
              <Form.Label>IFSC Code <span className="error">*</span></Form.Label>
              <Form.Control
                type="text"
                name="IFSCCode"
                value={formData.IFSCCode}
                onChange={handleChange}
                onBlur={checkValidation}
              />
              <span className="error">{ifscErr}</span>
              <Form.Control.Feedback type="invalid">
                Please provide a valid IFSC code.
              </Form.Control.Feedback>
            </Form.Group>
            </div>
            <div className="row">
            <Form.Group controlId="AccountType" className="w-50">
              <Form.Label>Account Type <span className="error">*</span></Form.Label>
              <Form.Control
                type="text"
                name="AccountType"
                value={formData.AccountType}
                onChange={handleChange}
                onBlur={checkValidation}
              />
              <span className="error">{actypeErr}</span>
              <Form.Control.Feedback type="invalid">
                Please provide a valid account type.
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="OnlineTransactionNo" className="w-50">
              <Form.Label>PhonePe/Gpay/Paytm <span className="error">*</span></Form.Label>
              <Form.Control
                type="number"
                name="OnlineTransactionNo"
                value={formData.OnlineTransactionNo}
                onChange={handleChange}
                onBlur={checkValidation}
              />
              <span className="error">{onmobiletypeErr}</span>
              <Form.Control.Feedback type="invalid">
                Please provide a valid account type.
              </Form.Control.Feedback>
            </Form.Group>
            </div>
           
            <div className="row">
            
            <Form.Group controlId="address" className="w-50">
              <Form.Label>Address <span className="error">*</span></Form.Label>
              <Form.Control name="Address" as="textarea" rows={3}
                value={formData.Address}
                onChange={handleChange}

              />
              <span className="error">{vendorAddresErr}</span>
              <Form.Control.Feedback type="invalid">
                Please provide a valid address.
              </Form.Control.Feedback>
            </Form.Group>
            </div>
            <div className="row">
              <div className="col-sm-4 offset-sm-4">
            <Button className="btn-block w-100" type="submit">Update</Button>
            </div>
            </div>
          </Form>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default VendorEdit;