import React, { useState } from "react";
import { Container, Navbar } from "react-bootstrap";
import { Outlet } from "react-router-dom";
import SideBar from "./SideBar.component";
import { useNavigate } from "react-router-dom";

import styled from 'styled-components';

import "./InnerContext.css";

const Main = styled.main`
    position: relative;
    overflow: hidden;
    transition: all .15s;
    padding: 0px;
    margin-left: ${props => (props.expanded ? 240 : 64)}px;
`;

export default function InnerContent() {
  const [expanded, setExpanded] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const navigate = useNavigate();

  const logOut = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    navigate("/login");
  }
  const setAction = (extended) => {
    setExpanded(extended);
  }
  return (
    <>

    <div>
      <SideBar setAction={(extended) => setAction(extended)} />

      <Main expanded={expanded} >
          <Outlet />
      </Main>
    </div>
    {/* <div id="mybutton">
      <div className={menuOpen ? "floatingIcon open" : "floatingIcon"} onClick={() => setMenuOpen(menuOpen ? false : true)}>
        <div class="button"></div>
        <div class="button"></div>
        <div class="button"></div>
        <div class="button" onClick={logOut}></div>
      </div>
    </div> */}
    {/* <div className="row" >
      <div className="col-lg-12">
        <img
          src={require("../../Assets/Images/logo.png")}
          alt="Train Dhaba"
          style={{ width: "20%" }}
        ></img>
      </div>
    </div>
    <div className="row" >
      <div className="col-lg-12">
      
      </div>
    </div> */}
  </>

  );
}
