import React, { useEffect, useState,useRef } from "react";
import CustomTable from "../TableComponent/CustomTable.component";
import "./Outlet.css";
import axios from "axios";
import Button from "react-bootstrap/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTrash,
  faEdit,
  faCircleCheck,
  faBan,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
//import NewOutlet from "./newOutlet.component";
import { useNavigate } from 'react-router-dom';
import Header from "../header/Header";
import Footer from "../footer/Footer";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { Toast } from 'primereact/toast';

export default function Outlets() {
  const [outletsData, setOutletsData] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [currentRow, setCurrentRow] = useState({});
  const [visible, setVisible] = useState(false);
  const toast = useRef(null);

  const columns = [
    {
      dataField: "_id",
      text: "Id",
      hidden: true,
    },
    {
      dataField: "isActive",
      text: "Is Available",
      hidden: true,
    },
    {
      dataField: "Station_Id.StationName",
      text: "Station Name",
      classes: 'st-name mobile-td',
      sort: true,
      headerStyle: (column, colIndex) => {
        return { width: "250px" };
      },
    },
    {
      dataField: "ContactPerson",
      text: "Contact Person",
      classes: 'cp mobile-td',
      sort: true,
      headerStyle: (column, colIndex) => {
        return { width: "200px" };
      },
    },
    {
      dataField: "EmailAddess",
      text: "Contact Email",
      classes: 'ce mobile-td',
      sort: true,
      headerStyle: (column, colIndex) => {
        return { width: "200px" };
      },
    },
    {
      dataField: "Address",
      text: "Address",
      classes: 'address mobile-td',
      sort: true,
      headerStyle: (column, colIndex) => {
        return { width: "200px" };
      },
    },
    {
      dataField: "Order_Timing",
      text: "Order Timing",
      classes: 'ot mobile-td',
      sort: true,
      headerStyle: (column, colIndex) => {
        return { width: "200px" };
      },
    },
    {
      dataField: "Min_Order",
      text: "Min Order",
      classes: 'mo mobile-td',
      sort: true,
      headerStyle: (column, colIndex) => {
        return { width: "200px" };
      },
    },
   
    {
      dataField: "Delivery_Cost",
      text: "Delivery Cost",
      classes: 'dc mobile-td',
      sort: true,
      headerStyle: (column, colIndex) => {
        return { width: "200px" };
      },
    },
    {
      dataField: "Prepaid",
      text: "Prepaid",
      classes: 'prepaid mobile-td',
      sort: true,
      headerStyle: (column, colIndex) => {
        return { width: "200px" };
      },
    },
    {
      dataField: "State",
      text: "State",
      classes: 'state mobile-td',
      sort: true,
      headerStyle: (column, colIndex) => {
        return { width: "200px" };
      },
    },
    {
      dataField: "City",
      text: "City",
      classes: 'city mobile-td',
      sort: true,
      headerStyle: (column, colIndex) => {
        return { width: "200px" };
      },
    },
   
    {
      dataField: "Opening_Time",
      text: "Opening Time",
      classes: 'op mobile-td',
      sort: true,
      headerStyle: (column, colIndex) => {
        return { width: "200px" };
      },
    },
    {
      dataField: "Closing_Time",
      text: "Closing Time",
      classes: 'ct mobile-td',
      sort: true,
      headerStyle: (column, colIndex) => {
        return { width: "200px" };
      },
    },
    
    {
      dataField: "GST_NO",
      text: "GST NO",
      classes: 'gst mobile-td',
      sort: true,
      headerStyle: (column, colIndex) => {
        return { width: "200px" };
      },
    },
    {
      dataField: "FSSAI_NO",
      text: "FSSAI NO",
      classes: 'fssai mobile-td',
      sort: true,
      headerStyle: (column, colIndex) => {
        return { width: "160px" };
      },
    },
    {
      dataField: "FSSAI_Valid_Upto",
      text: "FSSAI Valid Upto",
      classes: 'fvu mobile-td',
      sort: true,
      headerStyle: (column, colIndex) => {
        return { width: "300px" };
      },
    },
    {
      dataField: "Closing_Period",
      text: "Closing Period",
      classes: 'cperiod mobile-td',
      sort: true,
      headerStyle: (column, colIndex) => {
        return { width: "200px" };
      },
    },
    {
      dataField: "WeeklyClosed",
      text: "Weekly Closed",
      classes: 'wc mobile-td',
      sort: true,
      headerStyle: (column, colIndex) => {
        return { width: "200px" };
      },
    },
    {
      dataField: "VendorId.VendorName",
      text: "Vendor Name",
      classes: 'vn mobile-td',
      sort: true,
      headerStyle: (column, colIndex) => {
        return { width: "200px" };
      },
    },
    {
      dataField: "VendorId.EmailID",
      text: "Vendor EmailID",
      classes: 've mobile-td',
      sort: true,
      headerStyle: (column, colIndex) => {
        return { width: "200px" };
      },
    },
    {
      dataField: "VendorId.MobileNumber",
      text: "Vendor Mobile Number",
      classes: 'vm mobile-td',
      sort: true,
      headerStyle: (column, colIndex) => {
        return { width: "240px" };
      },
    },
    {
      dataField: "VendorId.PANNumber",
      text: "Vendor PAN Number",
      classes: 'vp mobile-td',
      sort: true,
      headerStyle: (column, colIndex) => {
        return { width: "200px" };
      },
    },
    {
      dataField: "_id",
      text: "Actions",
      formatter: ActiveFormatter,
      headerStyle: (column, colIndex) => {
        return { width: "150px" };
      },
    },
  ];
  function makeid(length) {
    var result = "";
    var characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }
  function ActiveFormatter(cell, row) {
    return (
      <>
        <div className="action-btns" >
          {row.isActive && (
            <>
                {/* <a>
                  <FontAwesomeIcon key={makeid(10)} icon={faEdit} />
                </a>
                <a onClick={() => ActivateOutlet(row._id, false)}>
                  <FontAwesomeIcon key={makeid(10)} icon={faBan} />
                </a> */}
                <a onClick={() => DeleteOutlet(row)}>
                  <FontAwesomeIcon key={makeid(10)} icon={faTrash} />
                </a>
            </>
          )}
          {!row.isActive && (
            <div className="action-btns">
              <a onClick={() => ActivateOutlet(row._id, true)}>
                <FontAwesomeIcon key={makeid(10)} icon={faCircleCheck} />
              </a>
            </div>
          )}
        </div>
      </>
    );
  }
  useEffect(() => {
    getAllOutlets();
  }, []);

  const confirm = () => {
    DeleteOutletAPI(currentRow);
  }

  const ignore = () => {
    setVisible(true);
  }

  function DeleteOutlet(row) {
    setCurrentRow(row);
    setVisible(true);
  
  }

  function DeleteOutletAPI(row) {
    setCurrentRow(row);
    setVisible(true);
    axios
      .delete(process.env.REACT_APP_BASE_URL + `/Outlets/deleteOutlet/${row._id}`)
      .then((response) => {
        if (response.data.success) {
          getAllOutlets();
        }
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }

  function getAllOutlets() {
    axios
    .get(process.env.REACT_APP_BASE_URL + "/Outlets/getAllOutlets")
      .then((response) => {
        if (response.data.success) {
          setOutletsData(response.data.body);
        }
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }

  function returnAction(reloadFlag){
    setIsOpen(false);
    if(reloadFlag)
    {
      getAllOutlets();
    }
  }

  const navigate = useNavigate ();

const handleNavigation = () => {
  navigate('/outlet');
};


  function ActivateOutlet(Id, flag) {
    axios
      .put(process.env.REACT_APP_BASE_URL +`/Outlets/update/${Id}`, {
        isActive: flag,
      })
      .then((response) => {
        if (response.data.success) {
          getAllOutlets();
        }
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }
  return (
    <>
      
      <Header />
      <div className="page-container">
        <div className="page-header">
          <h4 className="page-title">Outlets List</h4>
          <Button className="float-right" onClick={() => handleNavigation()}>
          <FontAwesomeIcon icon={faPlus} />  Add New Outlet
            </Button>
        </div>
        <div className="box-container">
            <CustomTable data={outletsData} columns={columns}></CustomTable>
        </div>
      </div>
      <Footer />
      <div>
        {/* <NewOutlet
          isOpen={isOpen}
          returnAction={(reloadFlag) => returnAction(reloadFlag)}
        ></NewOutlet> */}
      </div>
      <Toast ref={toast} />
     <ConfirmDialog visible={visible} onHide={() => setVisible(false)} message="Are you sure you want to proceed?" 
                header="Confirmation" icon="pi pi-exclamation-triangle" accept={confirm} reject={ignore} />
    </>
  );
}
