import React, { useEffect, useState, useRef } from "react";
import CustomTable from "../TableComponent/CustomTable.component";
import "./MenuItems.css";
import axios from "axios";
import Button from "react-bootstrap/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTrash,
  faEdit,
  faCircleCheck,
  faBan,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import NewMenuItem from "./NewMenuItems.component";
import { useNavigate } from 'react-router-dom';
import Papa from "papaparse";

import Header from "../header/Header";
import Footer from "../footer/Footer";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { Toast } from 'primereact/toast';

export default function MenuItems() {
  const [menuItemsData, setMenuItemsData] = useState([]);
  const [outletsData, setOutletsData] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [currentRow, setCurrentRow] = useState({});
  const [visible, setVisible] = useState(false);
  const toast = useRef(null);
  const navigate = useNavigate();
  const fileInputRef = useRef(null);
  const [cSVParsedData, setCSVParsedData] = useState([])
  const [selectedOutletId, setSelectedOutletId] = useState('')
  const [vendors, setVendors] = useState([]);
  const [selectedVendorId, setSelectedVendorId] = useState('')

  const [isDeleteAll, setIsDeleteAll] = useState(false)

  const columns = [
    {
      dataField: "_id",
      text: "Id",
      hidden: true,
    },
    {
      dataField: "Item_Name",
      text: "Item Name",
      classes: 'item-name mobile-td',
      sort: true,
      headerStyle: (column, colIndex) => {
        return { width: "200px"};
      },
    },
    {
      dataField: "Item_Price",
      text: "Item Price",
      classes: 'item-price mobile-td',
      sort: true,
      headerStyle: (column, colIndex) => {
        return { width: "130px" };
      },
    },
    {
      dataField: "Base_Price",
      text: "Base Price",
      classes: 'base-price mobile-td',
      sort: true,
      headerStyle: (column, colIndex) => {
        return { width: "130px" };
      },
    },
    {
      dataField: "Tax",
      text: "Tax",
      classes: 'tax mobile-td',
      sort: true,
      headerStyle: (column, colIndex) => {
        return { width: "100px"};
      },
    },
    {
      dataField: "Selling_Price",
      text: "Selling Price",
      classes: 'selling-price mobile-td',
      sort: true,
      headerStyle: (column, colIndex) => {
        return { width: "150px" };
      },
    },
    {
      dataField: "Tags",
      text: "Tags",
      classes: 'tags mobile-td',
      sort: true,
      headerStyle: (column, colIndex) => {
        return { width: "200px" };
      },
    },
    {
      dataField: "isActive",
      text: "Is available",
      classes: 'available mobile-td',
      sort: true,
      headerStyle: (column, colIndex) => {
        return { width: "140px"};
      },
    },
    {
      dataField: "isGST",
      text: "GST",
      classes: 'gst mobile-td',
      sort: true,
      headerStyle: (column, colIndex) => {
        return { width: "100px"};
      },
    },
    {
      dataField: "Cuisine.CuisineType",
      text: "Cuisine",
      classes: 'cuisine mobile-td',
      sort: true,
      headerStyle: (column, colIndex) => {
        return { width: "150px" };
      },
    },
    {
      dataField: "Food_Type.FoodType",
      text: "Food Type",
      classes: 'food-type mobile-td',
      sort: true,
      headerStyle: (column, colIndex) => {
        return { width: "150px"};
      },
      // align: (cell, row, rowIndex, colIndex) => {
      //   if (rowIndex % 2 === 0) return "right";
      //   return "left";
      // },
    },
    {
      dataField: "New_Item_Price",
      text: "New Item Price",
      classes: 'new-item-price mobile-td',
      sort: true,
      headerStyle: (column, colIndex) => {
        return { width: "180px" };
      },
    },
    {
      text: "Item Type",
      classes: 'item-type mobile-td',
      sort: true,
      formatter: ItemTypeFormatter,
      align: "center",
      headerStyle: (column, colIndex) => {
        return { width: "130px" };
      },
    },
    {
      dataField: "_id",
      text: "Actions",
      formatter: ActiveFormatter,
      headerStyle: (column, colIndex) => {
        return { width: "150px"};
      },
    },
  ];

  const confirm = () => {
    DeleteMenuItemAPI(currentRow);
    if(isDeleteAll === true){
      confirmAllDelete()

    }
    
  }

  const ignore = () => {
    setVisible(true);
  }

  function DeleteMenuItem(row) {
    setCurrentRow(row);
    setVisible(true);
  
  }

  function DeleteMenuItemAPI(row) {
    setCurrentRow(row);
    setVisible(true);
    axios
      .delete(process.env.REACT_APP_BASE_URL + `/MenuItems/deleteMenuItem/${row._id}`)
      .then((response) => {
        if (response.data.success) {
          console.log("outlet", row.Outlet_Id._id)
          loadMenuItems(row.Outlet_Id._id);
        }
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }


  const handleNavigation = () => {
    navigate('/AddMenuItem');
  };

  function ItemTypeFormatter(cell, row) {
    return (
      <>
        <div className="item-type-image">
          {row.Is_Vegetarian && (
            <>
                <img src={require("../../Assets/Images/veg.png")}
                />
            </>
          )}
          {row.Is_Non_Vegetarian && (
            
              <img
                src={require("../../Assets/Images/non-veg.png")}
              />
          )}
        </div>
      </>
    );
  }
  function makeid(length) {
    var result = "";
    var characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }
  function ActiveFormatter(cell, row) {
    return (
      <>
        <div className="action-btns" >
          {row.isActive && (
            <>
                {/* <a>
                  <FontAwesomeIcon key={makeid(10)} icon={faEdit} />
                </a>
                <a>
                  <FontAwesomeIcon key={makeid(10)} icon={faBan} />
                </a> */}
                <a title="Delete" onClick={() => DeleteMenuItem(row)}>
                  <FontAwesomeIcon key={makeid(10)} icon={faTrash} />
                </a>
            </>
          )}
          {!row.isActive && (
            
              <a>
                <FontAwesomeIcon key={makeid(10)} icon={faCircleCheck} />
              </a>
          )}
        </div>
      </>
    );
  }
  useEffect(() => {
    getActiveOutlets();
  }, []);

  function OnOutletChange(selectedOutlet) {
    if (selectedOutlet === "Select Outlet") {
      setMenuItemsData([]);
    } else {
      loadMenuItems(selectedOutlet);
      setSelectedOutletId(selectedOutlet)
    }
  }

  function loadMenuItems(selectedOutlet) {
    axios
      .get(
        process.env.REACT_APP_BASE_URL + `/MenuItems/getMenuItemsByOutlet/${selectedOutlet}`
      )
      .then((response) => {
        if (response.data.success) {
          setMenuItemsData(response.data.body);
        }
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }
  function getActiveOutlets() {
    axios
      .get(process.env.REACT_APP_BASE_URL + "/Outlets/getOutletsDD")
      .then((response) => {
        if (response.data.success) {
          setOutletsData(response.data.body);
        }
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }
  const handleFileUpload= () =>{
    fileInputRef.current.click();

  }
  const handleBulkPush = async () => {
    try {
      // Check if outlet and vendor are selected
      if (!selectedOutletId || !selectedVendorId) {
        alert("Please select both Outlet and Vendor before bulk upload.");
        return;
      }
  
      // Iterate through each row in the CSV file
      for (const each of cSVParsedData) {
        let form_Data = new FormData();
        form_Data.append('menuInfo', JSON.stringify(each));
  
        // Make an individual POST request and wait for it to finish
        const response = await axios.post(
          process.env.REACT_APP_BASE_URL + "/MenuItems/createMenuItem",
          form_Data
        );
  
        // Handle the response
        if (response.data.success) {
          loadMenuItems(selectedOutletId)
          console.log('Request succeeded:', response.data);
          console.log("request", response.data)
        } else {
          console.log('Request failed:', response.data.msg);
        }
      }
  
      // After handling all responses, you can navigate or perform additional actions
      // handleNavigation();
  
    } catch (error) {
      console.error("There was an error!", error);
    }
  };
  
  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    // Now you can handle the selected file, for example, upload it to the server
    console.log('Selected File:', selectedFile);
    const reader = new FileReader();

    
  
    if (selectedFile && selectedFile.name.endsWith(".csv")) {
      reader.onload = async ({ target }) => {
        const csv = Papa.parse(target.result, {
          header: true,
        });
        const parsedData = csv?.data;
        console.log("parsed-Data", parsedData, parsedData.slice(0,parsedData.length-1))
        const updatedParsedData = parsedData.slice(0,parsedData.length-1)
        
        if (updatedParsedData && updatedParsedData.length > 0) {
          const formattedData = updatedParsedData.map(row => {
            const rowData = {...row, Outlet_Id: selectedOutletId, VendorId: selectedVendorId};
            Object.entries(row).forEach(([key, value]) => {
              rowData[key] = value;
            });
            return rowData;
          });


          console.log("formate-in-prsing", formattedData)
        
          setCSVParsedData(formattedData);
          
        }
      };
  
      reader.readAsText(selectedFile);
    }
  };

  function getAllVendors() {
    axios
      .get(process.env.REACT_APP_BASE_URL + "/vendors/getAllVendors")
      .then((response) => {
        if (response.data.success) {
          setVendors(response.data.body);
        }
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }


  useEffect(() => {
    getAllVendors();
   
  }, [])

  const handleDeleteAllMenuItems = () => {
    console.log("Deleting all menu items...");
    // setMenuItemsData([])
    setVisible(true);
    setIsDeleteAll(true)
    

  


    
  };


  const confirmAllDelete= () =>{
    if (isDeleteAll === true){
      for(const eachRow of menuItemsData){
        // setCurrentRow(row);
        axios
          .delete(process.env.REACT_APP_BASE_URL + `/MenuItems/deleteMenuItem/${eachRow._id}`)
          .then((response) => {
            if (response.data.success) {
              console.log("outlet", eachRow.Outlet_Id._id)
              loadMenuItems(eachRow.Outlet_Id._id);
            }
          })
          .catch((error) => {
            console.error("There was an error!", error);
          });
      }

    }
  }




  
  return (
    <>

      <Header />
      <div className="page-container">
        <div className="page-header menu-items-header">
          <div className="d-flex w-75 menu-dropdown">
            <h4 className="page-title">Menu Items List for </h4>
            <div className="m-2">
              <>
              <select
                class="form-select"
                aria-label=".form-select-lg example"
                onChange={(e) => {
                  OnOutletChange(e.target.value);
                }}
              >
                <option selected>Select Outlet</option>
                {outletsData ? outletsData.map((i) => (
                  <option value={i._id} key={i._id}>
                    {i.OutletName}
                  </option>
                )) : null}
              </select>
             
              </>
            </div>
          </div>

          <Button
            className="float-right"
            onClick={handleNavigation}>
            <FontAwesomeIcon icon={faPlus} /> Add Menu
          </Button>
        </div>
          <div className="block-container">
          {menuItemsData.length > 0 && (
            <CustomTable data={menuItemsData} columns={columns} onDeleteAll={handleDeleteAllMenuItems}></CustomTable>
          )}
        </div>
        <div className='d-flex w-45 menu-dropdown justify-content-between align-items-center mt-3'>
          <h4 className="page-title">Menu Items Bulk Upload</h4>
          <div className='d-flex w-55 justify-content-between'>
          <select className="form-select" aria-label=".form-select-lg example"   onChange={(e) => {
                  setSelectedVendorId(e.target.value);
                }}>
              <option value="">Select Vendor</option>
                {vendors.map((i) => (
                  <option value={i._id} key={i._id}>
                    {i.VendorName}
                  </option>
                ))}

              </select>
          <Button
            className="float-right" style={{marginRight:"10px", marginLeft:"10px"}}
            onClick={handleFileUpload}>
            <FontAwesomeIcon icon={faPlus} /> File Upload
          </Button>
          <input
          type="file"
          ref={fileInputRef}
          style={{ display: 'none' }}
          onChange={handleFileChange}
          accept=".csv, application/vnd.ms-excel, text/csv"

        />
          <Button
            className="float-right"
            onClick={handleBulkPush}>
            <FontAwesomeIcon icon={faPlus} /> Bulk Push
          </Button>
          </div>
        </div>
      </div>
      <Footer />

      {/* <div style={{ backgroundColor: "#fff" }}>
        <div className="row mainHeader">
          <div className="col-md-4">
            <h4 className="labeltextcolor">Menu Items List for </h4>
            <select
              class="form-select form-select-lg mb-3"
              aria-label=".form-select-lg example"
              onChange={(e) => {
                OnOutletChange(e.target.value);
              }}
            >
              <option selected>Select Outlet</option>
              {outletsData.map((i) => (
                <option value={i._id} key={i._id}>
                  {i.OutletName}
                </option>
              ))}
            </select>
          </div>
          <div className="col-md-8" style={{ textAlign: "right" }}>
            <Button
              className="float-right"
              onClick={handleNavigation}>
              Add Menu
            </Button>
          </div>
        </div>
        <div className="col-md-12 mainHeader">
          {menuItemsData.length > 0 && (
            <CustomTable data={menuItemsData} columns={columns}></CustomTable>
          )}
        </div>
      </div> */}
      <div>
        {/* <NewMenuItem
          isOpen={isOpen}
          returnAction={() => setIsOpen(false)}
        ></NewMenuItem> */}
      </div>
      <Toast ref={toast} />
     <ConfirmDialog visible={visible} onHide={() => setVisible(false)} message="Are you sure you want to proceed?" 
      header="Confirmation" icon="pi pi-exclamation-triangle" accept={confirm} reject={ignore} />

    </>
  );
}
