import React, {useEffect,useState} from "react";
import CustomTable from "../TableComponent/CustomTable.component";
import Header from "../header/Header";
import Footer from "../footer/Footer";
import axios from "axios";
import {convertDateToMilliseconds, convertDateToIST,convertDeliveryDate,convertBookingDate} from '../Utility/Helper'
import "./Enquiries.css";
export default function Enquiry() {
    const [enquireData,setEnquireData] = useState([]);

    useEffect(() => {
       // setEnquireData([]);
       getAllEnquiries();
      }, []);

      function getAllEnquiries() {
        axios
          .get(process.env.REACT_APP_BASE_URL + "/enquiries/getAllEnquiries")
          .then((response) => {
            if (response.data.success) {
              response.data.body.forEach((element) => {
                console.log(element.createdAt);
                element.createdAt =  convertBookingDate(element.createdAt)
              });
              setEnquireData(response.data.body);
            }
          })
          .catch((error) => {
            console.error("There was an error!", error);
          });
      }
    const columns = [
        {
          dataField: "_id",
          text: "Id",
          hidden: true,
        },
        // {
        //   dataField: "Item_Name",
        //   text: "Name",
        //   sort: true,
        //   headerStyle: (column, colIndex) => {
        //     return { width: "100px", textAlign: "center" };
        //   },
        // },
        {
          dataField: "emailID",
          text: "Email",
          sort: true,
          classes: 'email mobile-td',
          headerStyle: (column, colIndex) => {
            return { width: "250px" };
          },
        },
        {
          dataField: "mobileNumber",
          text: "Mobile Number",
          classes: 'mobile mobile-td',
          sort: true,
          headerStyle: (column, colIndex) => {
            return { width: "180px" };
          },
        },
        {
          dataField: "message",
          text: "Message",
          classes: 'message mobile-td',
          sort: true,
          headerStyle: (column, colIndex) => {
            return { width: "200px" };
          },
        },
        {
          dataField: "type",
          text: "Type",
          classes: 'type mobile-td',
          sort: true,
          headerStyle: (column, colIndex) => {
            return { width: "100px" };
          },
        },
        {
          dataField: "createdAt",
          text: "Date",
          classes: 'date mobile-td',
          sort: true,
          headerStyle: (column, colIndex) => {
            return { width: "150px"};
          },
        },
      ];
  return (
    <>
         <Header/>   
      <div className="page-container">
        <div className="page-header">
          <h4 className="page-title">Enquiries</h4> 
        </div>
        <div className="box-container">
        <CustomTable data={enquireData} columns={columns}></CustomTable>
        </div>
      </div>
      <Footer/>
    </>
  );
}
