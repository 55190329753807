import React, { useState } from "react";
import "./Login.css";
import {
  Form,
  FormFeedback,
  FormGroup,
  Label,
  Input,
  Button,
} from "reactstrap";
import { useNavigate } from "react-router-dom";
import axios from "axios";

export default function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [validate, setValidate] = useState({});
  const navigate = useNavigate();

  const validateEmail = (e) => {
    const emailRex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    let validate = {};

    if (emailRex.test(e.target.value)) {
      validate.emailState = "has-success";
    } else {
      validate.emailState = "has-danger";
    }

    setValidate(validate);
  };
  

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {     
      loginAPI();
    }
  }

  function loginAPI()
  {
    let user = {
      emailID: email,
      password: password,
    };
    axios
      .post(process.env.REACT_APP_BASE_URL + "/admin/authenticate", user)
      .then((response) => {
        if (response.data.success) {
          localStorage.setItem("token", response.data.body.token);
          localStorage.setItem("user", JSON.stringify(response.data.body));
          navigate("/dashboard");
        }
        else
        {
          alert(response.data.error);
        }
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }

  const submitForm = (e) => {
    //localStorage.setItem("user", "test");
    loginAPI();
  };

  return (
    <>
      <div className="logo-container">
      <div className="logo-icon">
          <img
            src={require("../../Assets/Images/td-logo.png")}
            alt="Train Dhaba"
          ></img>
        </div>
        <h3 class="text-center">Sign In - Admin</h3>
        <Form className="form">
          <FormGroup>
            <Label>Username</Label>
            <Input
              type="email"
              name="email"
              id="exampleEmail"
              placeholder="example@example.com"
              valid={validate.emailState === "has-success"}
              invalid={validate.emailState === "has-danger"}
              value={email}
              onChange={(e) => {
                validateEmail(e);
                setEmail(e.target.value);
              }}
              onKeyDown={handleKeyDown}      
            />
            <FormFeedback>
              Uh oh! Looks like there is an issue with your email. Please input
              a correct email.
            </FormFeedback>
          </FormGroup>
          <FormGroup>
            <Label for="examplePassword">Password</Label>
            <Input
              type="password"
              name="password"
              id="examplePassword"
              placeholder="********"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              onKeyDown={handleKeyDown}              
            />
          </FormGroup>{" "}
          <div className="text-center">
            <Button  className="w-100" color="primary" onClick={(e) => submitForm(e)}>
              Login
            </Button>
          </div>
        </Form>
      </div>
    </>
  );
}
