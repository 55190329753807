export const navigationItems = {
  sidebar: [
    {
      name: "Dashboard ",
      to: "/dashboard",
      text: "dashboard",
      icon: "faDashboard",
    },
    {
      name: "Stations ",
      to: "/Stations",
      text: "Stations",
      icon: "faTrain",
    },
    {
      name: "Vendor",
      to: "/Vendors",
      text: "Vendor",
      icon: "faUserAstronaut",
    },
    {
      name: "Outlets ",
      to: "/Outlets",
      text: "Outlets",
      icon: "faShop",
    },
    {
      name: "Menu Items ",
      to: "/MenuItems",
      text: "MenuItems",
      icon: "faPizzaSlice",
    },
    {
      name: "Outlet Users ",
      to: "/OutletUsers",
      text: "users",
      icon: "faUsers",
    },
    {
      name: "Enquiries ",
      to: "/Enquiries",
      text: "Enquiries",
      icon: "faBolt",
    },
    {
      name: "Refunds ",
      to: "/Refunds",
      text: "Refunds",
      icon: "faMoneyBillWave",
    },
    {
      name: "Orders ",
      to: "/Orders",
      text: "Orders",
      icon: "faKitchenSet",
    },
    {
      name: "IRCTCPush ",
      to: "/IRCTCPush",
      text: "IRCTCPush",
      icon: "faDatabase",
    },
    {
      name: "Invoices ",
      to: "/Invoices",
      text: "Invoices",
      icon: "faMoneyBillWave",
    },
{
  name: "Invoices List ",
  to: "/InvoicesList",
  text: "InvoicesList",
  icon: "faMoneyBillWave",
    },
    {
      name: "Logout ",
      to: "/login",
      text: "Logout",
      icon: "faSignOut",
    },
  ],
  footer: [],
};
export default navigationItems;
