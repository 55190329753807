import React, { useEffect, useState } from "react";
import CustomTable from "../TableComponent/CustomTable.component";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTrash,
  faEdit,
  faCircleCheck,
  faBan,
} from "@fortawesome/free-solid-svg-icons";
import Bundle from "react-bootstrap-time-picker";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import "./Orders.css";
import Swal from 'sweetalert2';
import Header from "../header/Header";
import Footer from "../footer/Footer";
import {convertDateToMilliseconds, convertDateToIST,convertDeliveryDate,convertBookingDate} from '../Utility/Helper'

export default function Orders() {
  const [ordersData, setOrdersData] = useState([]);
  const [orderStatus, setOrderStatus] = useState([]);
  const [eachVendor, setEachVendor] = useState({});
  const [show, setShow] = useState(false);
  const [isNew, setIsNew] = useState(true);
  const [currentOrderStatusId, setCurrentOrderStatusId] = useState("");
  const [currentOrderStatus, setCurrentOrderStatus] = useState({});
  const [filterData, setfilterData] = useState([]);
  useEffect(() => {
    setOrdersData([]);
    setOrderStatus([]);
    getAllOrders('All');
    getAllOrderStatus();
    var optionsdata = [
     // {_id:'All',value:'All'},
      {_id:'Today',value:'Today'},
      {_id:'Delayed',value:'Delayed'},
      {_id:'Upcoming',value:'Upcoming'},
      
     // {_id:'Others',value:'Others'}
     ];
     setfilterData(optionsdata);
  }, []);

  function getAllOrders(type) {
    axios
      .get(process.env.REACT_APP_BASE_URL + `/order/getAllOrdersByType/${type}`)
      .then((response) => {
        if (response.data.success) {
          console.log(response.data.body);
         // console.log(response.data.body[0].Booking_Date);
         // console.log(response.data.body.Booking_Date);
          response.data.body.forEach((element) => {
            console.log(element.Booking_Date);
            element.Booking_Date =  convertBookingDate(element.Booking_Date)
            element.Delivery_Date =  convertDeliveryDate(element.Delivery_Date)
            element.Order_Status_Id.OrderStatus =  element.Order_Status_Id.OrderStatus.replace('ORDER_', '');
            element.Total_Amount.$numberDecimal =  "Rs."+element.Total_Amount.$numberDecimal;

          });
          setOrdersData(response.data.body);
        }
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }

  function getAllOrderStatus() {
    axios
      .get(process.env.REACT_APP_BASE_URL + "/order/getAllOrderStatus")
      .then((response) => {
        if (response.data.success) {
          console.log(response.data.body);
         
          setOrderStatus(response.data.body);
        }
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }
  function OnOrdersChange(type) {
   
    if (type === "Select") {
     // setMenuItemsData([]);
     getAllOrders("All");
    } else {
     // alert(type)
      getAllOrders(type);

    }
  }

  function handleClose() {
    setShow(false);
    setIsNew(true);
    setEachVendor({});
  }

  function onChange(e, controlName) {
    let eachVend = eachVendor;
    eachVend[controlName] = e.target.value;
    setEachVendor(eachVend);
    console.log("controlname", controlName);
    console.log("OrderStatus", e.target.value);
    if (controlName == "OrderStatus") {
      setCurrentOrderStatusId(e.target.value);
    }
  }

  const columns = [
    {
      dataField: "_id",
      text: "Id",
      hidden: true,
    },
    {
      dataField: "Order_Id",
      text: "Order Id",
      classes: 'order_id mobile-td',
      sort: true,
      headerStyle: (column, colIndex) => {
        return { width: "130px" };
      },
    },
    {
      dataField: "PNR",
      text: "PNR #",
      classes: 'consumer_name mobile-td',
      sort: true,
      headerStyle: (column, colIndex) => {
        return { width: "110px" };
      },
    }, 
    {
      dataField: "Consumer_Name",
      text: "Consumer Name",
      classes: 'consumer_name mobile-td',
      sort: true,
      headerStyle: (column, colIndex) => {
        return { width: "140px" };
      },
    }, 
    {
      dataField: "User_Id.mobileNumber",
      text: "Mobile Number",
      classes: 'mobile mobile-td',
      sort: true,
      headerStyle: (column, colIndex) => {
        return { width: "150px" };
      },
    },
    {
      dataField: "User_Id.emailID",
      text: "Email",
      classes: 'email mobile-td',
      sort: true,
      headerStyle: (column, colIndex) => {
        return { width: "220px" };
      },
    },
    {
      dataField: "Total_Amount.$numberDecimal",
      text: "Total Amt (Rs.) ",
      classes: 'amt mobile-td',
      sort: true,
      headerStyle: (column, colIndex) => {
        return { width: "100px" };
      },
    },
    {
      dataField: "Booking_Date",
      text: "Booking Date",
      classes: 'booking_date mobile-td',
      sort: true,
      headerStyle: (column, colIndex) => {
        return { width: "160px" };
      },
    },
    {
      dataField: "Delivery_Date",
      text: "Delivery Date",
      classes: 'delivery_date mobile-td',
      sort: true,
      headerStyle: (column, colIndex) => {
        return { width: "160px" };
      },
    },
    {
      dataField: "StationName",
      text: "Station Name",
      classes: 'StationName mobile-td',
      sort: true,
      headerStyle: (column, colIndex) => {
        return { width: "160px" };
      },
    },
    {
      dataField: "StationCode",
      text: "Station Code",
      classes: 'delivery_date mobile-td',
      sort: true,
      headerStyle: (column, colIndex) => {
        return { width: "160px" };
      },
    },
    {
      dataField: "Vendor_Id.VendorName",
      text: "Vendor Name",
      classes: 'vendor_name mobile-td',
      sort: true,
      headerStyle: (column, colIndex) => {
        return { width: "160px" };
      },
    },
    {
      dataField: "Outlet_Id.OutletName",
      text: "Outlet Name",
      classes: 'vendor_name mobile-td',
      sort: true,
      headerStyle: (column, colIndex) => {
        return { width: "160px" };
      },
    },
    {
      dataField: "Order_Status_Id.OrderStatus",
      text: "Order Status",
      classes: 'status mobile-td',
      sort: true,
      headerStyle: (column, colIndex) => {
        return { width: "140px" };
      },
    },
    {
      dataField: "_id",
      text: "Actions",
      formatter: ActiveFormatter,
      headerStyle: (column, colIndex) => {
        return { width: "120px" };
      },
    },
  ];

  function AddNewVendor(row) {
    setCurrentOrderStatus(row);
    if (ordersData && ordersData.length > 0) {
      var filteredObj = orderStatus.map((i) => {
        if (i._id == row.Order_Status_Id._id) {
          setCurrentOrderStatusId(row.Order_Status_Id._id);
        }
      });
      console.log("currentOrderStatus ==" + currentOrderStatusId);
      //setCurrentOrderStatus
    }
    setShow(true);
    setIsNew(true);
    setEachVendor({});
  }

  function handlerUpdateOrderStatus() {

    let orderudpate = {
      order_Id: currentOrderStatus._id,
      order_Status_Id: currentOrderStatusId
    }

    axios
      .post(process.env.REACT_APP_BASE_URL + "/order/updateOrderStatus", orderudpate)
      .then((response) => {
        if (response.data.success) {
          setShow(false);
          setIsNew(true);
          setEachVendor({});
          getAllOrders();
          Swal.fire({
            position: "center",
            icon: "success",
            title: "Order status updated successfully.",
            showConfirmButton: false,
            timer: 3500
          });
        }
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }

  function makeid(length) {
    var result = "";
    var characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  function ActiveFormatter(cell, row) {
    return (
      <>
        <Button variant="primary" type="submit" onClick={() => {
          AddNewVendor(row);
        }}>Update</Button>
      </>
    );
  }

  return (
    <>
      <Header />
      <div className="page-container">
        <div className="page-header menu-items-header">
        <div className="d-flex w-75 menu-dropdown">
          <h4 className="page-title">Orders</h4>
          <div className="m-2">
              <select
                class="form-select"
                aria-label=".form-select-lg example"
                onChange={(e) => {
                  OnOrdersChange(e.target.value);
                }}
              >
                <option selected>All</option>
                {filterData ? filterData.map((i) => (
                  <option value={i._id} key={i._id}>
                    {i.value}
                  </option>
                )) : null}
              </select>
            </div>
          </div>
        </div>
        <div className="box-container">
          <CustomTable data={ordersData} columns={columns}></CustomTable>
        </div>
      </div>
      <Footer />
      <div>
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>{isNew ? "Add " : "Update "} Update Order Status</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <Form>


              <Form.Group className="mb-3" controlId="formBasicPassword">
                <Form.Label> Order Status</Form.Label>
                <Form.Select onChange={(e) => onChange(e, "OrderStatus")}>
                  {orderStatus && orderStatus.length > 0 ? orderStatus.map((i) => (
                    <option value={i._id} key={i._id} selected={i._id == currentOrderStatusId}>
                      {i.OrderStatus}
                    </option>
                  )) : null}

                </Form.Select>
              </Form.Group>
            </Form>
          </Modal.Body>

          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button variant="primary" type="submit" onClick={handlerUpdateOrderStatus}>
              Update
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
}
