import React, {useEffect,useState,useRef} from "react";
import CustomTable from "../TableComponent/CustomTable.component";
import Header from "../header/Header";
import Footer from "../footer/Footer";
import set from "lodash/set";
import axios from "axios";
import { Form, Button, Container,Card, Col,  } from 'react-bootstrap';
import { Toast } from 'primereact/toast';




export default function IRCTCPush() {   
    const [orderAPIResponse,setAPIResponse] = useState("");
    const [isLoading,setIsLoading] = useState(false);
    const [stationsData, setStationsData] = useState([]);
    const [stationId, setStationId] = useState("");
    const [stationCode, setStationCode] = useState("");
    const [outletData, setOutletData] = useState([]);
    const [outletId, setOutletId] = useState("");
    const toast = useRef(null);


    useEffect(() => {
      getAllStations();
      }, []);

      const style = {
        height:'100%',
        width:'100%',
          resize:'none',
          padding:'9px',
          boxSizing:'border-box',
          fontSize:'15px'};

 function pushOrdersDataToIRCTC()
 {  
  setIsLoading(true);
    axios
    .get(process.env.REACT_APP_BASE_URL +`/Irctc/pushBulkOrdersToIRCTC`)
    .then((response) => {
      if (response.data.success) {
        console.log("response",response.data.body);       
        setAPIResponse(response.data.body);
        setIsLoading(false);
      }
    })
    .catch((error) => {
      setIsLoading(false);
      console.error("There was an error!", error);
    });
 }

 function pushOutletsDataToIRCTC()
 {  
  if(!stationId || stationId == "")
  {
    alert("Please select station");
    return;
  } 
  setIsLoading(true);
    axios
    .get(process.env.REACT_APP_BASE_URL +`/Irctc/pushBulkOutletsToIRCTC/${stationId}`)
    .then((response) => {
      if (response.data.success) {
        console.log("response",response.data.body);       
        setAPIResponse(response.data.body);
        setIsLoading(false);
      }
    })
    .catch((error) => {
      setIsLoading(false);
      console.error("There was an error!", error);
    });
 }

 function pushMenuItemsToIRCTC()
 { 
  if(!stationCode || stationCode == "")
  {
    alert("Please select station");
    return;
  } 

  if(!outletId || outletId == "")
  {
    alert("Please select outlet");
    return;
  } 
  setIsLoading(true);
    axios
    .get(process.env.REACT_APP_BASE_URL +`/Irctc/pushMenuItemsToIRCTC/${outletId}/${stationCode}`)
    .then((response) => {
      if (response.data.success) {
        console.log("response",response.data.body);       
        setAPIResponse(response.data.body);
        setIsLoading(false);
      }
      else
      {
        setAPIResponse(response.data.body);
        setIsLoading(false);
      }
    })
    .catch((error) => {
      setIsLoading(false);
      console.error("There was an error!", error);
    });
 }

 function getAllStations() {
  axios
    .get(process.env.REACT_APP_BASE_URL +"/stations/getAllStations")
    .then((response) => {
      if (response.data.success) {
        setStationsData(response.data.body);
        console.log("Stations List",stationsData)
      }
    })
    .catch((error) => {
      console.error("There was an error!", error);
    });
}

function getOutletByStationCode(_stationCode) {
  axios
    .get(process.env.REACT_APP_BASE_URL +`/Outlets/getOutletsByStationCode/${_stationCode}`)
    .then((response) => {
      if (response.data.success) {
        setOutletData(response.data.body);
        console.log("outletData",outletData)
        if(!response.data.body || response.data.body.length == 0)
        {
          toast.current.show({ severity: 'error', summary: 'Error', detail: 'There is no outlets for the station', life: 4000 });
        }
      }
    })
    .catch((error) => {
      toast.current.show({ severity: 'success', summary: 'Error', detail: 'There is no outlets for the station', life: 4000 });
      console.error("There was an error!", error);
    });
}

function getMenuItemsByOutletId(_outletId)
{  
 setIsLoading(true);
   axios
   .get(process.env.REACT_APP_BASE_URL +`/MenuItems/getOutletMenuInfoToPush/${_outletId}`)
   .then((response) => {
     if (response.data.success) {
       console.log("response",response.data.body);  
       setIsLoading(false);

       if(!response.data.body || response.data.body.length == 0)
       {
         toast.current.show({ severity: 'error', summary: 'Error', detail: 'There is no menu items for the outlet', life: 4000 });
       }
     }
   })
   .catch((error) => {
    toast.current.show({ severity: 'error', summary: 'Error', detail: 'There is no menu items for the outlet', life: 4000 });
  
     setIsLoading(false);
     console.error("There was an error!", error);
   });
}

const handleChange = e => { 
  setStationId(e.target.value); 
  const { name, value } = e.target;
  stationsData.map((i)=>{
    if(i._id == e.target.value)
    {
      getOutletByStationCode(i.StationCode);      
    }
  })
 
 };

 const handleMenuItemStationCodeChange = e => {  
  setStationCode(e.target.value); 
   getOutletByStationCode(e.target.value);
 };

 const handleMenuItemOutletChange = e => { 
  setOutletId(e.target.value);   
  getMenuItemsByOutletId(e.target.value)
 };
   
  return (
    <>
         

      <Header/>
      <div className="page-container">
        <div className="page-header">
          <h4 className="page-title">Data Push To IRCTC</h4> 
          
        </div>
        <div className="box-container"> 
        
        <div className="row">
          
        <div className="col-sm-6 mb-3">
                <Form.Group className="mb-6" controlId="formStation_Id">
                  <Form.Label>Station Name <span style={{ color: "red" }}>*</span></Form.Label>

                  <Form.Select
                    class="form-select form-select-lg mb-3"
                    aria-label=".form-select-lg example"
                    onChange={handleChange}
                    name="Station_Id"
                  >
                    <option selected>Select Station</option>
                    {stationsData.map((i) => (
                      <option value={i._id} key={i._id}>
                        {i.StationName} - ({i.StationCode})
                      </option>
                    ))}
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    Please select a valid station.
                  </Form.Control.Feedback>
                </Form.Group>
               
              </div>
              <div className="col-sm-6 mb-3">
              <button className="btn btn-primary data-push-btns" onClick={pushOutletsDataToIRCTC} disabled={isLoading} >Outlets Push</button> &nbsp; <button className="btn btn-primary data-push-btns" onClick={pushOrdersDataToIRCTC} disabled={isLoading} >Orders Push</button>
              </div>
        </div>

        <div className="row">
        <div className="col-sm-3 mb-3">
                <Form.Group className="mb-2" controlId="formStation_Id">
                  <Form.Label>Station Name <span style={{ color: "red" }}>*</span></Form.Label>

                  <Form.Select
                    class="form-select form-select-lg mb-3"
                    aria-label=".form-select-lg example"
                    onChange={handleMenuItemStationCodeChange}
                    name="Station_Id"
                  >
                    <option selected>Select Station</option>
                    {stationsData.map((i) => (
                      <option value={i.StationCode} key={i.StationCode}>
                        {i.StationName} - ({i.StationCode})
                      </option>
                    ))}
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    Please select a valid station.
                  </Form.Control.Feedback>                  
                </Form.Group>
        </div>
        <div className="col-sm-3 mb-3">
                <Form.Group className="mb-2" controlId="formStation_Id">
                  <Form.Label>Outlet <span style={{ color: "red" }}>*</span></Form.Label>

                  <Form.Select
                    class="form-select form-select-lg mb-3"
                    aria-label=".form-select-lg example"
                    onChange={handleMenuItemOutletChange}
                    name="Station_Id"
                  >
                    <option selected>Select Outlet</option>
                    {outletData.map((i) => (
                      <option value={i._id} key={i._id}>
                        {i.OutletName}
                      </option>
                    ))}
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    Please select a outlet.
                  </Form.Control.Feedback>
                </Form.Group>
               
              </div>
              <div className="col-sm-6 mb-3">
              <button className="btn btn-primary data-push-btns" onClick={pushMenuItemsToIRCTC} disabled={isLoading} >MenuItems Push</button> &nbsp; 
              </div>
        </div>

        
       
         
         <textarea  style={style}  rows={30} columns={10} readOnly defaultValue={orderAPIResponse}></textarea>
        </div>
      </div>
      <Footer/>
      <Toast ref={toast} />
    </>
  );
}
