import React, { useEffect, useState,useRef } from "react";
import CustomTable from "../TableComponent/CustomTable.component";
import axios from "axios";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import "./Vendor.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTrash,
  faEdit,
  faCircleCheck,
  faBan,
  faKey,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import Header from "../header/Header";
import Footer from "../footer/Footer";
import { useNavigate } from 'react-router-dom';
import "primereact/resources/themes/lara-light-indigo/theme.css";
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { Toast } from 'primereact/toast';

export default function Vendors() {
  const [vendorsData, setVendorsData] = useState([]);
  const [eachVendor, setEachVendor] = useState({});
  const [show, setShow] = useState(false);
  const [isNew, setIsNew] = useState(true);
  const [defaultValue, setDefaultValue] = useState("");
  const [currentRow, setCurrentRow] = useState({});
  const [visible, setVisible] = useState(false);
  const toast = useRef(null);

  const navigate = useNavigate();

  const confirm = () => {
    deleteVendorAPI(currentRow);
  }

  const ignore = () => {
    setVisible(true);
  }

  const handleNavigation = () => {
    navigate('/vendor');
  };

  const handleEditNavigation = (row) => {
    navigate('/vendorEdit/'+row._id);
  };

  useEffect(() => {
    getAllVendors();
  }, []);
  function AddNewVendor() {
    setShow(true);
    setIsNew(true);
    setEachVendor({});
  }
  function getAllVendors() {
    axios
      .get(process.env.REACT_APP_BASE_URL + "/vendors/getAllVendors")
      .then((response) => {
        if (response.data.success) {
          setVendorsData(response.data.body);
        }
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }

  function makeid(length) {
    var result = "";
    var characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  const columns = [
    {
      dataField: "_id",
      text: "Id",
      hidden: true,
    },
    {
      dataField: "VendorName", 
      text: "Vendor Name", 
      sort: true,
      classes: 'v-name mobile-td',
      headerStyle: (column, colIndex) => {
        return { width: "150px" };
      },
    },
    {
      dataField: "user_Id.emailID",
      text: "Email Address",
      sort: true,
      classes: 'email mobile-td',
      headerStyle: (column, colIndex) => {
        return { width: "230px" };
      },
    },
    {
      dataField: "MobileNumber", text: "Mobile Number",
      classes: 'mobile mobile-td',
      headerStyle: (column, colIndex) => {
        return { width: "140px" };
      },
    },
    {
      dataField: "PhoneNumber", text: "Phone Number",
      classes: 'phone mobile-td',
      headerStyle: (column, colIndex) => {
        return { width: "140px" };
      },
    },
    {
      dataField: "PANNumber", text: "PAN Number",
      classes: 'pan mobile-td',
      headerStyle: (column, colIndex) => {
        return { width: "140px" };
      },
    },
    {
      dataField: "Address",
      text: "Address",
      classes: 'address mobile-td',
      headerStyle: (column, colIndex) => {
        return { width: "220px" };
      },
    },
    {
      text: "Actions",
      formatter: ActiveFormatter,
      headerStyle: (column, colIndex) => {
        return { width: "180px" };
      },
    },
  ];

  function onChange(e, controlName) {
    let eachVend = eachVendor;
    eachVend[controlName] = e.target.value;
    setEachVendor(eachVend);
  }
  function handleClose() {
    setShow(false);
    setIsNew(true);
    setEachVendor({});
  }

  function updatePassword(Id) {
    axios
      .put(process.env.REACT_APP_BASE_URL + `/vendors/update/${Id}`, {
        Password: "abc@123",
      })
      .then((response) => {
        if (response.data.success) {
          getAllVendors();
        }
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }

  function onVendorUpdate(row) {
    setShow(true);
    setIsNew(false);
    setEachVendor(row);
  }
  function ActiveFormatter(cell, row) {
    return (
      <>
        <div className="action-btns" >
          {row.isActive && (
            <>
              <a title="Edit" onClick={() => handleEditNavigation(row)}>
                <FontAwesomeIcon key={makeid(10)} icon={faEdit} />
              </a>

              <a title="Deactivate" onClick={() => ActivateVendor(row._id, false)}>
                <FontAwesomeIcon key={makeid(10)} icon={faBan} />
              </a>

              <a title="Delete" onClick={() => DeleteVendor(row)}>
                <FontAwesomeIcon key={makeid(10)} icon={faTrash} />
              </a>
              <a title='Update Password' onClick={() => updatePassword(row._id)}>
                <FontAwesomeIcon key={makeid(10)} icon={faKey} />
              </a>
            </>
          )}
          {!row.isActive && (
            <a title="Activate" onClick={() => ActivateVendor(row._id, true)}>
              <FontAwesomeIcon key={makeid(10)} icon={faCircleCheck} />
            </a>
          )}
        </div>
      </>
    );
  }

  function addVendor() {
    axios
      .post(process.env.REACT_APP_BASE_URL + "/vendors/create", eachVendor)
      .then((response) => {
        if (response.data.success) {
          getAllVendors();
          handleClose();
        } else {
          alert(response.data.error);
          handleClose();
        }
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }

  function DeleteVendor(row) {
    setVisible(true);
    setCurrentRow(row);
      
  }

  function deleteVendorAPI(row)
  {
    axios
    .put(process.env.REACT_APP_BASE_URL + `/vendors/update/${row._id}`, {
      isDelete: true,
    })
    .then((response) => {
      if (response.data.success) {
        getAllVendors();
      }
    })
    .catch((error) => {
      console.error("There was an error!", error);
    });
  }

  function ActivateVendor(Id, flag) {
    axios
      .put(process.env.REACT_APP_BASE_URL + `/vendors/update/${Id}`, {
        isActive: flag,
      })
      .then((response) => {
        if (response.data.success) {
          getAllVendors();
        }
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }



  return (
    <>


      <Header />
      <div className="page-container">
        <div className="page-header">
          <h4 className="page-title">Vendors List</h4>
          <Button
            className="float-right"
            onClick={() => {
              handleNavigation();
            }}
          >
            <FontAwesomeIcon icon={faPlus} />  Add New Vendor
          </Button>
        </div>
        <div className="box-container">
          <CustomTable data={vendorsData} columns={columns}></CustomTable>
        </div>
      </div>
      <Footer />

      <div>
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>{isNew ? "Add " : "Update "} Vendor</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <Form>
              <Form.Group className="mb-3" controlId="formVendorName">
                <Form.Label>Vendor Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Station Name"
                  // defaultValue={eachVendor.VendorName}
                  onChange={(e) => onChange(e, "VendorName")}

                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="formBasicPassword">
                <Form.Label>Email Address</Form.Label>
                <Form.Control
                  type="email"
                  disabled={isNew ? false : true}
                  placeholder="Enter Email Address"
                  defaultValue={defaultValue}
                  onChange={(e) => onChange(e, "EmailID")}

                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="formBasicPassword">
                <Form.Label>Password</Form.Label>
                <Form.Control
                  type="password"
                  disabled={isNew ? false : true}
                  placeholder="Enter Password"
                  defaultValue={defaultValue}
                  onChange={(e) => onChange(e, "Password")}
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="formBasicPassword">
                <Form.Label>Mobile Number</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Mobile Number"
                  defaultValue={eachVendor.MobileNumber}
                  onChange={(e) => onChange(e, "MobileNumber")}
                />
              </Form.Group>
              {/* <Form.Group className="mb-3" controlId="formBasicPassword">
                <Form.Label>Phone Number</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Phone Number"
                  defaultValue={eachVendor.PhoneNumber}
                  onChange={(e) => onChange(e, "PhoneNumber")}
                />
              </Form.Group> */}

              <Form.Group className="mb-3" controlId="formBasicPassword">
                <Form.Label>PAN Number</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter PAN Number"
                  defaultValue={eachVendor.PANNumber}
                  onChange={(e) => onChange(e, "PANNumber")}
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="formBasicPassword">
                <Form.Label>GST Number</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter GST Number"
                  defaultValue={eachVendor.GSTNumber}
                  onChange={(e) => onChange(e, "GSTNumber")}
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="formBasicPassword">
                <Form.Label>Address</Form.Label>
                <Form.Control
                  type="textarea"
                  placeholder="Enter Address"
                  defaultValue={eachVendor.Address}
                  onChange={(e) => onChange(e, "Address")}
                />
              </Form.Group>
            </Form>
          </Modal.Body>

          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button variant="primary" type="submit" onClick={addVendor}>
              {isNew ? "Save" : "Update"}
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
      <Toast ref={toast} />
     <ConfirmDialog visible={visible} onHide={() => setVisible(false)} message="Are you sure you want to proceed?" 
                header="Confirmation" icon="pi pi-exclamation-triangle" accept={confirm} reject={ignore} />
    </>
  );
}
