import React, { useEffect, useState } from "react";
import axios from "axios";
import { Form, Button, Container } from 'react-bootstrap';
import { Routes, Route, Navigate, Link, useNavigate } from "react-router-dom";


import TimePicker from 'react-bootstrap-time-picker';
import { cities } from "indian-cities-json";
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import CheckboxGroup from 'react-checkbox-group'
import Datetime from 'react-datetime';
import "react-datetime/css/react-datetime.css";
import "./Outlet.css";

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import Header from "../header/Header";
import Footer from "../footer/Footer";

const _ = require("lodash");


const OutletAdd = () => {

  const [outletInfo, setOutletInfo] = useState({});
  //const [isOpen, setIsOpen] = useState(false);
  const [isNew, setIsNew] = useState(false);
  const [loadStations, setLoadStations] = useState([]);
  const [loadVendors, setLoadVendors] = useState([]);
  const [statesList, setStatesList] = useState([]);
  const [citiesList, setCitiesList] = useState([]);
  //const [phoneNumber, setPhoneNumber] = useState();
  const [selectedLogo, setSelectedLogo] = useState(null);
  const [selectedNutriDocument, setSelectedNutriDocument] = useState(null);
  const [openingTime, setOpeningTime] = useState(null);
  const [closingTime, setClosingTime] = useState(null);
  const [weeklyClosed, setWeeklyClosed] = useState([])
  const [fssaiValidUpto, setFssaiValidUpto] = useState(null);
  const [isDuplicate, setIsDuplicate] = useState(false);
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    //setIsOpen(props.isOpen);
    setIsNew(true);
    getAllStations();
    getStatesList();
    getAllVendors();
  }, []);

  /*const [formData, setFormData] = useState({
    OutletName: '',
    Station_Id: '',
    password: '',
    confirmPassword: '',
  });*/

  const [errors, setErrors] = useState({
    OutletName: '',
    Station_Id: '',
    password: '',
    confirmPassword: '',
  });


  function onStateChange(e) {
    let selectedState = e.target.value;
    const { name, value } = e.target;
    setOutletInfo(prevState => ({
      ...prevState,
      [name]: value
    }));
    setCitiesList(_.orderBy(_.filter(cities, function (o) { return o.state === selectedState; }), ['name'], ['asc']));
  }

  const documentSelectedHandler = (event) => {
    setSelectedNutriDocument(event.target.files[0]);
  }
  function onPrepaidChange(e) {
    let selectedValue = e.target.value;

    if (selectedValue !== 'Select Order Type') {
      setOutletInfo(prevState => ({
        ...prevState,
        ['Prepaid']: Boolean(selectedValue)
      }));
    }
    else {
      setOutletInfo(prevState => ({
        ...prevState,
        ['Prepaid']: null
      }));
    }
  }

  const handleChange = e => {
    console.log(outletInfo);
    const { name, value } = e.target;
    setOutletInfo(prevState => ({
      ...prevState,
      [name]: value
    }));
    if (name == 'OutletName') {
      setIsDuplicate(false);
    }
  };
  function getStatesList() {
    setStatesList(
      _.orderBy(
        _.uniqBy(cities, function (e) {
          return e.state;
        }), ['state'], ['asc']))
  }
  function getAllStations() {
    axios
      .get(process.env.REACT_APP_BASE_URL + "/stations/getAllStations")
      .then((response) => {
        if (response.data.success) {
          setLoadStations(response.data.body);
        }
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }

  function getAllVendors() {
    axios
      .get(process.env.REACT_APP_BASE_URL + "/vendors/getAllVendors")
      .then((response) => {
        if (response.data.success) {
          setLoadVendors(response.data.body);
        }
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }

  const selectedOpeningTime = (event) => {
    var date = new Date(0);
    date.setSeconds(event); // specify value for SECONDS here
    var timeString = date.toISOString().substr(11, 8);
    console.log(timeString)
    setOpeningTime(timeString);
    setOutletInfo(prevState => ({
      ...prevState,
      ['Opening_Time']: timeString
    }));
  }


  const selectedClosingTime = (event) => {
    var date = new Date(0);
    date.setSeconds(event); // specify value for SECONDS here
    var timeString = date.toISOString().substr(11, 8);
    console.log(timeString)
    setClosingTime(timeString);
    setOutletInfo(prevState => ({
      ...prevState,
      ['Closing_Time']: timeString
    }));
  }

  function onFSSAIDateChange(event) {
    setFssaiValidUpto(event._d);
    setOutletInfo(prevState => ({
      ...prevState,
      ['FSSAI_Valid_Upto']: event._d
    }));
  }

  const [selectedDate, setSelectedDate] = useState("");
  const [selectedFromDate, setSelectedFromDate] = useState("");
  const [selectedToDate, setSelectedToDate] = useState("");

  const handleDateChange = (date) => {
    const inputDate = date.toLocaleString();
    console.log("selected date1:", date);
    // console.log("selected toLocaleDateString:",date.toLocaleDateString()); 
    //console.log("selected toISOString:",date.toISOString());
    setSelectedDate(date);
    setOutletInfo(prevState => ({
      ...prevState,
      ['FSSAI_Valid_Upto']: inputDate
    }));
    console.log("outletInfo -", JSON.stringify(outletInfo));
  };

  const handleFromDateChange = (date) => {
    const inputDate = date.toLocaleString();
    console.log("selected date:", date);
    setSelectedFromDate(date);
    setOutletInfo(prevState => ({
      ...prevState,
      ['Closing_Period_From']: inputDate
    }));
    console.log("outletInfo -", JSON.stringify(outletInfo));
  };

  const handleToDateChange = (date) => {
    const inputDate = date.toLocaleString();
    console.log("selected date:", date);
    setSelectedToDate(date);
    setOutletInfo(prevState => ({
      ...prevState,
      ['Closing_Period_To']: inputDate
    }));
    console.log("outletInfo -", JSON.stringify(outletInfo));
  };

  const logoSelectedHandler = (event) => {
    var selectedFile = event.target.files[0];
    var idxDot = selectedFile.name.lastIndexOf(".") + 1;
    var extFile = selectedFile.name.substr(idxDot, selectedFile.name.length).toLowerCase();
    if (extFile == "jpg" || extFile == "jpeg" || extFile == "png") {
      setSelectedLogo(event.target.files[0]);
    } else {
      alert("Only jpg/jpeg and png files are allowed!");
      event.target.value = null;
    }

  }

  const [validated, setValidated] = useState(false);

  const navigate = useNavigate();

  const handleNavigation = () => {
    navigate('/Outlets');
  };

  const handleActiveChange = (e) => {
    console.log("FORM DATA:", setOutletInfo)
    const { name, value } = e.target;
    console.log("name:", name, " value:", value);
    setIsActive(!isActive);
    setOutletInfo(prevState => ({
      ...prevState,
      [name]: !isActive
    }));
    console.log("Updated form data:", setOutletInfo)
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    // let files = [];
    let OutletInfo = outletInfo;
    //OutletInfo.ContactMobileNumber = phoneNumber;
    outletInfo.WeeklyClosed = weeklyClosed;
    let formData = new FormData();
    /*Object.keys(outletInfo).forEach((key) => {
      formData.append(key, outletInfo[key])
    })*/
    formData.append('outletInfo', JSON.stringify(outletInfo));
    if (selectedLogo != null)
      formData.append('file', selectedLogo, selectedLogo.name);
    if (selectedNutriDocument != null)
      formData.append('file', selectedNutriDocument, selectedNutriDocument.name);


    console.log("formdata", formData);
    axios
      .post(process.env.REACT_APP_BASE_URL + "/Outlets/create", formData)
      .then((response) => {
        if (response.data.success) {
          navigate('/Outlets');
        }
        else {
          console.log(response);
          //alert(response.data.error);
          if (response.data.msg == 'duplicate') {
            setIsDuplicate(true);
            alert("Outlet name already exists");
          }
        }
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  };

  return (
    <>

      <Header />
      <div className="page-container">
        <div className="page-header">
          <h4 className="page-title">Add Outlet</h4>
        </div>
        <div className="box-container">
          <div className="text-center">
            <span className="error">{isDuplicate ? 'Outlet already exists.' : ''}</span>
          </div>
          <Form onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-sm-6 mb-3">
                <Form.Group controlId="VendorId">
                  <Form.Label>Vendor <span className="error">*</span></Form.Label>
                  <Form.Select
                    onChange={handleChange}
                    name="VendorId"
                  >
                    <option value="">Select Vendor</option>
                    {loadVendors ? loadVendors.map((i) => (
                      <option value={i._id} key={i._id}>
                        {i.VendorName}
                      </option>
                    )) : null}

                  </Form.Select>
                  <span className="error">{errors.VendorId}</span>
                  <Form.Control.Feedback type="invalid">
                    Please select a valid vendor.
                  </Form.Control.Feedback>
                </Form.Group>
              </div>
              <div className="col-sm-6 mb-3">
                <Form.Group controlId="formContactPerson">
                  <Form.Label>Contact Person<span style={{ color: "red" }}>*</span></Form.Label>
                  <Form.Control type="text" onChange={handleChange}
                    name="ContactPerson" placeholder="Enter Contact Person" />
                </Form.Group>
              </div>

            </div>
            <div className="row">
              <div className="col-sm-6 mb-3">
                <Form.Group controlId="formOutletName">
                  <Form.Label>Outlet Name <span style={{ color: "red" }}>*</span></Form.Label>
                  <Form.Control
                    type="text"
                    name="OutletName"
                    onChange={handleChange}
                    placeholder="Enter Outlet Name"
                    required
                  />
                  <Form.Control.Feedback type="invalid">
                    Please provide a valid outlet name.
                  </Form.Control.Feedback>

                </Form.Group>
              </div>
              <div className="col-sm-6 mb-3">
                <Form.Group className="mb-6" controlId="formStation_Id">
                  <Form.Label>Station Name <span style={{ color: "red" }}>*</span></Form.Label>

                  <Form.Select
                    class="form-select form-select-lg mb-3"
                    aria-label=".form-select-lg example"
                    onChange={handleChange}
                    name="Station_Id"
                  >
                    <option selected>Select Station</option>
                    {loadStations.map((i) => (
                      <option value={i._id} key={i._id}>
                        {i.StationName} - ({i.StationCode})
                      </option>
                    ))}
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    Please select a valid station.
                  </Form.Control.Feedback>
                </Form.Group>
              </div>
            </div>

            <div className="row">
              <div className="col-sm-6 mb-3">
                <Form.Group controlId="formAddress">
                  <Form.Label>Address <span style={{ color: "red" }}>*</span></Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={2}
                    name="Address"

                    onChange={handleChange}
                    required
                  />
                  <Form.Control.Feedback type="invalid">
                    Please provide a valid address.
                  </Form.Control.Feedback>
                </Form.Group>
              </div>
              <div className="col-sm-6 mb-3">
                <Form.Group controlId="formOrder_Timing">
                  <Form.Label>Order Timings <span style={{ color: "red" }}>*</span></Form.Label>

                  <Form.Control
                    type="text"
                    name="Order_Timing"
                    onChange={handleChange}
                    required
                  />
                  <Form.Control.Feedback type="invalid">
                    Please provide a valid order timings.
                  </Form.Control.Feedback>
                </Form.Group>

              </div>
            </div>
            <div className="row">
              <div className="col-sm-6 mb-3">
                <Form.Group controlId="formOpeningTime">
                  <Form.Label>Opening Time <span style={{ color: "red" }}>*</span></Form.Label>
                  <TimePicker start="00:00" end="23:59" step={30} value={openingTime} onChange={selectedOpeningTime} initialValue="00:00"
                    name="Opening_Time" />
                  <Form.Control.Feedback type="invalid">
                    Please provide a valid opening time.
                  </Form.Control.Feedback>
                </Form.Group>
              </div>
              <div className="col-sm-6 mb-3">
                <Form.Group controlId="formClosingTime">
                  <Form.Label>Closing Time <span style={{ color: "red" }}>*</span></Form.Label>
                  <TimePicker start="00:00" end="23:59" step={30} value={closingTime} onChange={selectedClosingTime} initialValue="00:00"
                    name="Closing_Time" />
                  <Form.Control.Feedback type="invalid">
                    Please provide a valid closing time.
                  </Form.Control.Feedback>
                </Form.Group>
              </div>
            </div>

            <div className="row">
              <div className="col-sm-6 mb-3">
                <Form.Group controlId="formMin_Order">
                  <Form.Label>Minimum Order<span style={{ color: "red" }}>*</span></Form.Label>
                  <Form.Control type="number" onChange={handleChange}
                    name="Min_Order" placeholder="Min Order" />
                </Form.Group>
              </div>
              <div className="col-sm-6 mb-3">
                <Form.Group controlId="formDelivery_Cost">
                  <Form.Label>Delivery Cost <span style={{ color: "red" }}>*</span></Form.Label>
                  <Form.Control type="number" onChange={handleChange}
                    name="Delivery_Cost" placeholder="Delivery Cost" />
                </Form.Group>
              </div>
            </div>

            <div className="row">
              <div className="col-sm-6 mb-3">
                <Form.Group controlId="formSelectState">
                  <Form.Label>Select Sate<span style={{ color: "red" }}>*</span> </Form.Label>
                  <select
                    class="form-select"
                    aria-label=".form-select-lg example"
                    onChange={(e) => onStateChange(e)}
                    name="State"
                  >
                    <option selected>Select State</option>
                    {statesList.map((i) => (
                      <option value={i.state} key={i.state}>
                        {i.state}
                      </option>
                    ))}
                  </select>
                </Form.Group>
              </div>
              <div className="col-sm-6 mb-3">
                <Form.Group controlId="formSelectCity">
                  <Form.Label>Select City<span style={{ color: "red" }}>*</span></Form.Label>
                  <select
                    class="form-select"
                    aria-label=".form-select-lg example"
                    onChange={handleChange}
                    name="City"
                  >
                    <option selected>Select State</option>
                    {citiesList.map((i) => (
                      <option value={i.name} key={i.name}>
                        {i.name}
                      </option>
                    ))}
                  </select>
                </Form.Group>
              </div>
            </div>

            <div className="row">
              <div className="col-sm-6 mb-3">
                <Form.Group controlId="formCompanyName">
                  <Form.Label>Company Name<span style={{ color: "red" }}>*</span></Form.Label>
                  <Form.Control type="text" onChange={handleChange}
                    name="Company_Name" placeholder="Company Name" />
                </Form.Group>
              </div>
              <div className="col-sm-6 mb-3">
                <Form.Group controlId="formGST_NO">
                  <Form.Label>GST NO <span style={{ color: "red" }}>*</span></Form.Label>
                  <Form.Control type="text" onChange={handleChange}
                    name="GST_NO" placeholder="GST No" />
                </Form.Group>
              </div>
            </div>

            <div className="row">
              <div className="col-sm-6 mb-3">
                <Form.Group controlId="formFSSAINO">
                  <Form.Label>FSSAI NO<span style={{ color: "red" }}>*</span></Form.Label>
                  <Form.Control type="text" onChange={handleChange}
                    name="FSSAI_NO" placeholder="FSSAI NO" />
                </Form.Group>
              </div>
              <div className="col-sm-6 mb-3">
                <Form.Group controlId="formFSSAI_Valid_Upto">
                  <Form.Label>FSSAI Valid Up to<span style={{ color: "red" }}>*</span></Form.Label><br />

                  <DatePicker
                    selected={selectedDate}
                    onChange={handleDateChange}
                    className="form-control"
                    dateFormat="dd/MM/yyyy" // You can customize the date format
                    name="FSSAI_Valid_Upto"
                  />
                </Form.Group>
              </div>
            </div>

            <div className="row">
              <div className="col-sm-6 mb-3">
                <Form.Group controlId="formClosing_Period_From">
                  <Form.Label>Closing Period From <span style={{ color: "red" }}>*</span></Form.Label><br />

                  <DatePicker
                    selected={selectedFromDate}
                    onChange={handleFromDateChange}
                    className="form-control"
                    dateFormat="dd/MM/yyyy" // You can customize the date format
                    name="Closing_Period_From"
                  />
                </Form.Group>
              </div>
              <div className="col-sm-6 mb-3">
                <Form.Group controlId="formClosing_Period_To">
                  <Form.Label>Closing Period To <span style={{ color: "red" }}>*</span></Form.Label><br />

                  <DatePicker
                    selected={selectedToDate}
                    onChange={handleToDateChange}
                    className="form-control"
                    dateFormat="dd/MM/yyyy" // You can customize the date format
                    name="Closing_Period_To"
                  />
                </Form.Group>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-6 mb-3 CheckboxGroup">

                <Form.Group controlId="formSelectCity">
                  <Form.Label>Weekly Closed <span style={{ color: "red" }}>*</span></Form.Label>
                  <br />
                  <CheckboxGroup name="fruits" value={weeklyClosed} onChange={setWeeklyClosed}>
                    {(Checkbox) => (
                      <>
                        <label>
                          <Checkbox value="Mon" /> Monday
                        </label>
                        <label>
                          <Checkbox value="Tue" /> Tuesday
                        </label>
                        <label>
                          <Checkbox value="Wed" /> Wednesday
                        </label>
                        <label>
                          <Checkbox value="Thu" /> Thursday
                        </label>
                        <label>
                          <Checkbox value="Fri" /> Friday
                        </label>
                        <label>
                          <Checkbox value="Sat" /> Saturday
                        </label>
                        <label>
                          <Checkbox value="Sun" /> Sunday
                        </label>
                      </>
                    )}
                  </CheckboxGroup>

                </Form.Group>
              </div>
              <div className="col-sm-6 mb-3">
                <Form.Group controlId="formOrderType">
                  <Form.Label>Prepaid <span style={{ color: "red" }}>*</span></Form.Label>
                  <select
                    class="form-select"
                    aria-label=".form-select-lg example"
                    onChange={onPrepaidChange}
                    name="Prepaid"
                  >
                    <option selected>Select Order Type</option>
                    <option key="true" value="true">True</option>
                    <option key="false" value="false">False</option>

                  </select>
                </Form.Group>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-6 mb-3">
                <Form.Group controlId="formEmail">
                  <Form.Label>Email<span style={{ color: "red" }}>*</span></Form.Label>
                  <Form.Control type="text" onChange={handleChange}
                    name="EmailAddress" placeholder="Enter Email Address" />
                </Form.Group>
              </div>
              <div className="col-sm-6 mb-3">
                <Form.Group controlId="formMobile">
                  <Form.Label>Mobile<span style={{ color: "red" }}>*</span></Form.Label>
                  <Form.Control type="text" name="ContactMobileNumber" placeholder="Enter Mobile Number" onChange={handleChange} />
                </Form.Group>
              </div>
            </div>


            <div className="row">
              <div className="col-sm-6 mb-3">
                <Form.Group controlId="formFile" accept=".png, .jpg, .jpeg">
                  <Form.Label>LOGO</Form.Label>
                  <Form.Control type="file" onChange={logoSelectedHandler} />
                </Form.Group>
              </div>
              <div className="col-sm-6 mb-3">
                <Form.Group controlId="formFile">
                  <Form.Label>Nutritional Document</Form.Label>
                  <Form.Control type="file" onChange={documentSelectedHandler} accept="application/pdf,application/vnd.ms-excel" />
                </Form.Group>
              </div>
            </div>

            <div className="row">
              <div className="col-sm-6 mb-3 mt-2">
                <Form.Group controlId="isActive">
                  <Form.Check
                    type="switch"
                    name="isActive"
                    label="Outlet on/off"
                    checked={isActive}
                    onChange={handleActiveChange}
                  />
                </Form.Group>
                {/* <p>Switch is {isActive ? 'On' : 'Off'}</p> */}
              </div>
            </div>

            <div className="row">
              <div className="col-sm-4 offset-sm-4">
                <Button type="submit" className="btn-block w-100">Submit</Button>
              </div>
            </div>

          </Form>
        </div>
      </div>
      <Footer />
    </>
  )

}

export default OutletAdd;