import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

export default function Header() {
  const navigate = useNavigate();

  const logOut = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    navigate("/login");
  }

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleLogout = () => {
    // Handle the logout action here (e.g., clearing user session, redirecting, etc.)
    console.log('User logged out');
  };
    
  return (
    <>
        <header className="header">
            <div className='header-logo'>
            <img
          src={require("../../Assets/Images/logo.png")}
          alt="Vendor"
        />
            </div>
       

        <div className="profile-menu">
          <button onClick={toggleMenu} className="profile">
            <span>Train Dhaba Admin</span>
            <img
              src={require("../../Assets/Images/vendor-icon1.png")}
              alt="Vendor"
            />
          </button>
          {isMenuOpen && (
            <div class="dropdown-content">
              <a href="#">Profile</a>
              <a href="#">Settings</a>
              <a href="#" onClick={logOut}>Logout</a>
            </div>
          )}
        </div>
       
        </header>
    </>
  );
}
