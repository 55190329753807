import React from "react";
import MainRoutes from "./app.routes";
import "./App.css";
import axios from "axios";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


function App() {
  axios.interceptors.request.use(
    (req) => {
      if (!req.url.includes("authenticate")) {
        const token = localStorage.getItem("token");
        req.headers.Authorization = token;
      }
      return req;
    },
    (err) => {
      return Promise.reject(err);
    }
  );

  return (
    <div className="app">
      <MainRoutes />
      <ToastContainer theme="colored"/>
    </div>
  );
}

export default App;
