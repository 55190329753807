import React, {useEffect,useState} from "react";
import CustomTable from "../TableComponent/CustomTable.component";
import Header from "../header/Header";
import Footer from "../footer/Footer";
import {convertDateToMilliseconds, convertDateToIST,convertDeliveryDate,convertBookingDate} from '../Utility/Helper';
import axios from "axios";
import Button from "react-bootstrap/Button";
import Swal from 'sweetalert2';
import { Form, Container } from 'react-bootstrap';
import Modal from "react-bootstrap/Modal";
export default function InvoicesList() {
    const [invoiceListData,setinvoiceListData] = useState([]);
    const [showButton, setShowButton] = useState(true);
    const [invoice_id, setinvoice_id] = useState();
    const [show, setShow] = useState(false);
    const [isNew, setIsNew] = useState(true);
    const [selecteduploadDocument, setselecteduploadDocument] = useState(null);
    const [uploadInfo, setuploadInfo] = useState({});
    //const [vendorId, setVendorId] = useState("");
    useEffect(() => {
       // setRefundsData([]);
     
       getOnlineOrders();
       
      }, []);
     
      function handleClose() {
        setShow(false);
        setIsNew(true);
      }
      function uploadmodel(Id) {
        setShow(true);
        setIsNew(false);
        setinvoice_id(Id);
      }
      function acceptedinvoice(Id) {
        
        axios
          .put(process.env.REACT_APP_BASE_URL + `/invoice/adminacceptedinvoice/${Id}`)
          .then((response) => {
            if (response.data.success) {
                getOnlineOrders();
            }
          })
          .catch((error) => {
            console.error("There was an error!", error);
          });
      }
      const documentSelectedHandler = (event) => {
        setselecteduploadDocument(event.target.files[0]);
      }
      function getOnlineOrders() {
        axios
          .get(process.env.REACT_APP_BASE_URL + `/invoice/getInvoicesList`)
          .then((response) => {
            if (response.data.success) {
              console.log(response.data.body);
              response.data.body.forEach((element) => {
                element.InvoiceDate =  convertBookingDate(element.InvoiceDate);
                element.InvoiceAmount.$numberDecimal =  "Rs."+element.InvoiceAmount.$numberDecimal;
                element.TBAcceptStatus =  element.TBAcceptStatus?"Accepted":"Pending";


                
              });
              setinvoiceListData(response.data.body);
            }
          })
          .catch((error) => {
            console.error("There was an error!", error);
          });
      }


     
      
    
      const columns = [
        {
          dataField: "_id",
          text: "Id",
          hidden: true,
        },
        {
          dataField: "Invoice_No",
          text: "Invoice No",
          classes: 'Invoice_No mobile-td',
          sort: true,
          headerStyle: (column, colIndex) => {
            return { width: "160px" };
          },
        },
        {
            dataField: "Vendor_Id.VendorName",
            text: "Vendor Name",
            classes: 'email mobile-td',
            sort: true,
            headerStyle: (column, colIndex) => {
              return { width: "250px" };
            },
          },
        {
          dataField: "InvoiceDate",
          text: "Invoice Date",
          classes: 'consumer_name mobile-td',
          sort: true,
          headerStyle: (column, colIndex) => {
            return { width: "110px" };
          },
        }, 
        {
          dataField: "AccountingDate",
          text: "Accounting Date",
          classes: 'consumer_name mobile-td',
          sort: true,
          headerStyle: (column, colIndex) => {
            return { width: "180px" };
          },
        }, 
        {
          dataField: "DueDate",
          text: "Due Date",
          classes: 'mobile mobile-td',
          sort: true,
          headerStyle: (column, colIndex) => {
            return { width: "200px" };
          },
        },
       
        {
          dataField: "InvoiceAmount.$numberDecimal",
          text: "Invoice Amount (Rs.)",
          classes: 'amt mobile-td',
          sort: true,
          headerStyle: (column, colIndex) => {
            return { width: "140px" };
          },
        }, 
        {
            dataField: "UserType",
            text: "Generated By",
            classes: 'consumer_name mobile-td',
            sort: true,
            headerStyle: (column, colIndex) => {
              return { width: "180px" };
            },
          },  
          {
            dataField: "TBAcceptStatus",
            text: "TB accepct status",
            classes: 'consumer_name mobile-td',
            sort: true,
            headerStyle: (column, colIndex) => {
              return { width: "180px" };
            },
          },        
        {
          dataField: "_id",
          text: "Actions",
          formatter: ActiveFormatter,
          headerStyle: (column, colIndex) => {
            return { width: "120px" };
          },
        },
      ];

      function ActiveFormatter(cell, row) {
        return (
          <>
           {row.UserType=="Admin" && (
            <Button variant="primary" type="submit" onClick={() => acceptedinvoice(row._id)} >Accepct</Button>
            )}
            {row.UserType!="Admin" && (
            <Button variant="primary" type="submit" onClick={() => uploadmodel(row._id)} >Upload</Button>
            )}
            </>
        );
      }
      const handleSubmit = (e) => {

        e.preventDefault();
        alert();
        // let files = [];
        let uploadInfo = uploadInfo;
        uploadInfo.id = invoice_id;
        let formData = new FormData();
       
        formData.append('uploadInfo', JSON.stringify(uploadInfo));
       
        if (selecteduploadDocument != null)
          formData.append('file', selecteduploadDocument, selecteduploadDocument.name);
    
    
        console.log("formdata", formData);
        axios
          .post(process.env.REACT_APP_BASE_URL + "/invoice/createadmininvoiceupload", formData)
          .then((response) => {
            if (response.data.success) {
              
            }
            else {
              console.log(response);
              
            }
          })
          .catch((error) => {
            console.error("There was an error!", error);
          });
      };



  return (
    <>
      <Header/>
      <div className="page-container">
        <div className="page-header">
          <h4 className="page-title">Invoice List</h4> 
          
        </div>
        <div className="box-container">
          <CustomTable data={invoiceListData} columns={columns}></CustomTable>
        </div>
      </div>
      <Footer/>
      <div>
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title> Upload Invoice</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <Form >


            <Form.Group controlId="formFile">
                  <Form.Label> Document</Form.Label>
                  <Form.Control type="file" onChange={documentSelectedHandler} accept="application/pdf," />
                </Form.Group>
            </Form>
          </Modal.Body>

          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button variant="primary" type="submit" onClick={handleSubmit}>
              Upload
            </Button>
          </Modal.Footer>
        </Modal>
      </div>

    </>
  );
}
